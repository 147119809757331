export var primary = "#ac0926"
export var primaryLight = "#ac0926"
export var primaryDark = "#ac0926"
export var secondary = "#b62c44"
export var inheritDefault1 = "#fefefe"
export var inheritDefault2 = "#f8f9ff"
export var bgLight = "#f2f3f3"
export var bgDark = "#252f3f"
export var bgElementsLight = "#e8e8e8"
export var bgElementsDark = "#1e2e3a"
export var second = "#070919"
export var indigo = "#7420ff"
export var purple = "#793de6"
export var pink = "#fc26a4"
export var red = "#f83245"
export var orange = "#f4772e"
export var yellow = "#ffc926"
export var green = "#1bc943"
export var teal = "#18e1a5"
export var cyan = "#27dcf3"
export default {"primary":"#ac0926","primaryLight":"#ac0926","primaryDark":"#ac0926","secondary":"#b62c44","inheritDefault1":"#fefefe","inheritDefault2":"#f8f9ff","bgLight":"#f2f3f3","bgDark":"#252f3f","bgElementsLight":"#e8e8e8","bgElementsDark":"#1e2e3a","second":"#070919","indigo":"#7420ff","purple":"#793de6","pink":"#fc26a4","red":"#f83245","orange":"#f4772e","yellow":"#ffc926","green":"#1bc943","teal":"#18e1a5","cyan":"#27dcf3"}
