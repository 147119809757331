import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { OverlayView } from '@react-google-maps/api';
import { FaMapMarkerAlt } from 'react-icons/fa';

import { getEventColor } from '../../../utils/uiUtils';
import { setEventInfowindow } from '../../../reducers/MapReducer';

const positionOverlayView = (width, height) => ({
  x: -13,
  y: -26
});

const MarkersEvents = props => {
  const { events, setEventInfowindow } = props;

  const onClick = event => {
    setEventInfowindow(true, event);
  };

  const renderMarker = event => {
    if (event.LatitudeDegree !== null && event.LongitudeDegree !== null) {
      const latitude = `${
        event.LatitudeSign === '-' ? event.LatitudeSign : ''
      }${event.LatitudeDegree}`;
      const longitude = `${
        event.LongitudeSign === '-' ? event.LongitudeSign : ''
      }${event.LongitudeDegree}`;
      const position = {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
      };
      return (
        <OverlayView
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          key={event.ptsEventID}
          position={position}
          getPixelPositionOffset={positionOverlayView}>
          <Fragment>
            <div
              style={{
                position: 'sticky',
                marginLeft: '-22px',
                border: '1px solid white',
                padding: 0,
                background: 'rgba(255,255,255,0.6)',
                borderTopRightRadius: 5,
                borderBottomLeftRadius: 5
              }}>
              {event.CallType}
            </div>
            <FaMapMarkerAlt
              style={{ cursor: 'pointer' }}
              onClick={() => onClick(event)}
              size={26}
              color={getEventColor(event.Status)}
            />
          </Fragment>
        </OverlayView>
      );
    } else {
      return null;
    }
  };

  if (events && events.length > 0) {
    return events.map((event, index) => renderMarker(event));
  } else {
    return null;
  }
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  events: state.events.events
});

export default connect(mapStateToProps, { setEventInfowindow })(MarkersEvents);
