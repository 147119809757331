import store from '../config/configureStore';

import { getUnits } from './UnitsReducer';
import { getEvents } from './EventsReducer';

// FILTERS
const IS_OPEN = 'SETTINGS/IS_DIALOG_OPEN';
const AGENCY_FILTER = 'SETTINGS/AGENCY_FILTER';
const AGENCY_TYPE_FILTER = 'SETTINGS/AGENCY_TYPE_FILTER';

export const toggleDialog = () => {
  const _open = store.store.getState().settings.isOpen;
  return dispatch => {
    dispatch({ type: IS_OPEN, payload: !_open });
  };
};

export const setAgencyFilter = event => {
  const agencies = store.store.getState().data.agencies;
  const f = store.store.getState().settings.agencies;
  let filter;
  if (f === null) {
    filter = [];
    agencies.forEach(agency => {
      filter.push(agency.AgencyID);
    });
  } else {
    filter = [...store.store.getState().settings.agencies];
  }
  return async dispatch => {
    if (filter.length === 0) {
      filter.push(event.target.name);
      dispatch({ type: AGENCY_FILTER, payload: filter });
      dispatch(getUnits());
      dispatch(getEvents());
    } else {
      const isExist = filter.includes(event.target.name);
      const index = filter.indexOf(event.target.name);
      if (isExist === false) {
        filter.push(event.target.name);
        dispatch({ type: AGENCY_FILTER, payload: filter });
        dispatch(getUnits());
        dispatch(getEvents());
      } else {
        filter.splice(index, 1);
        dispatch({ type: AGENCY_FILTER, payload: filter });
        dispatch(getUnits());
        dispatch(getEvents());
      }
    }
  };
};

export const setAgencyTypeFilter = value => {
  return dispatch => {
    dispatch({ type: AGENCY_TYPE_FILTER, payload: value });
  };
};

export default function reducer(
  state = {
    // Dialog
    isOpen: false,
    // Filters
    agencies: null,
    agencyTypes: 7
  },
  action
) {
  switch (action.type) {
    case IS_OPEN:
      return {
        ...state,
        isOpen: action.payload
      };
    case AGENCY_FILTER:
      return {
        ...state,
        agencies: action.payload
      };
    case AGENCY_TYPE_FILTER:
      return {
        ...state,
        agencyTypes: action.payload
      };
    default:
      break;
  }
  return state;
}
