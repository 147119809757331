import store from '../config/configureStore';
import { setUserUnit } from './UserReducer';
import { getUnits } from './UnitsReducer';
import { getEvents } from './EventsReducer';

const IS_OPEN = 'UNIT_STATUS/IS_DIALOG_OPEN';

export const toggleDialog = () => {
  const _open = store.store.getState().unitStatus.isOpen;
  return dispatch => {
    dispatch({ type: IS_OPEN, payload: !_open });
  };
};

export const unitStatusChange = (
  status,
  event,
  location,
  _plate,
  _plateState,
  _oln,
  _olnState,
  _notes
) => {
  const client = store.store.getState().websocket.websocket;
  const myUnit = store.store.getState().user.userUnit;
  const _open = store.store.getState().unitStatus.isOpen;
  return async dispatch => {
    const service = client.service('units');
    service.timeout = 20000;
    await service.update(myUnit.ptsUnitID, {
      status: status,
      event: event,
      unit: myUnit,
      location: location,
      plate: _plate,
      plateState: _plateState,
      oln: _oln,
      olnState: _olnState,
      statusNotes: _notes
    });
    dispatch(getEvents());
    dispatch(getUnits());
    dispatch(setUserUnit());
    dispatch({ type: IS_OPEN, payload: !_open });
  };
};

export default function reducer(
  state = {
    isOpen: false
  },
  action
) {
  switch (action.type) {
    case IS_OPEN:
      return {
        ...state,
        isOpen: action.payload
      };
    default:
      break;
  }
  return state;
}
