import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import Draggable from 'react-draggable';

import { Card, CardContent } from '@material-ui/core';
import { IoIosCar } from 'react-icons/io';
import { FaWindowClose } from 'react-icons/fa';

import { setUnitInfowindow } from '../../../reducers/MapReducer';

import { getUnitColor } from '../../../utils/uiUtils';

const DialogUnit = props => {
  const { unitInfowindow, setUnitInfowindow } = props;

  const handleUnitInfoWindowClose = (state, infowindow) => {
    setUnitInfowindow(state, infowindow);
  };

  if (unitInfowindow.open === true) {
    return (
      <Draggable handle=".handle" positionOffset={{ x: '-50%', y: '-50%' }}>
        <div
          className="pts-unit-draggable"
          style={{
            position: 'absolute',
            zIndex: 999,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            padding: '0 5px'
          }}>
          <Card className="card-box pts-unit-box">
            <FaWindowClose
              onClick={() => handleUnitInfoWindowClose(false, null)}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: '3px',
                top: '3px',
                fontSize: '1.1rem'
              }}
            />
            <div
              className="card-indicator"
              style={{
                backgroundColor: getUnitColor(unitInfowindow.data.Path)
              }}
            />
            <CardContent>
              <div
                style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between'
                  }}>
                  <div
                    style={{ alignSelf: 'center', cursor: 'grab' }}
                    className="handle">
                    <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                      <IoIosCar
                        size={18}
                        color={getUnitColor(unitInfowindow.data.Path)}
                      />{' '}
                      {unitInfowindow.data.Unit}
                    </span>
                  </div>
                  <div>
                    <span
                      className="m-1 badge"
                      style={{
                        backgroundColor: getUnitColor(unitInfowindow.data.Path)
                      }}>
                      {unitInfowindow.data.UnitStatus}
                    </span>
                  </div>
                </div>
                <div style={{ display: 'block' }}>
                  <div style={{ fontStyle: 'italic' }}>
                    {moment(unitInfowindow.data.LastActionOccurred).format(
                      'MM/DD/YYYY, h:mm a'
                    )}
                  </div>
                </div>
                <div className="divider my-1" />
                <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                  Agency
                </div>
                <div>
                  <span style={{ fontSize: '0.8rem' }}>
                    {unitInfowindow.data.AgencyID}
                  </span>
                </div>
                <div className="divider my-1" />
                <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                  Division
                </div>
                <div>
                  <span style={{ fontSize: '0.8rem' }}>
                    {unitInfowindow.data.Division}
                  </span>
                </div>
                <div className="divider my-1" />
                <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                  Resources
                </div>
                <div>
                  <span style={{ fontSize: '0.8rem' }}>
                    {unitInfowindow.data.UnitResources}
                  </span>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </Draggable>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => ({
  network: state.offline,
  unitInfowindow: state.map.unitInfowindow
});

export default connect(mapStateToProps, { setUnitInfowindow })(DialogUnit);
