import React, { useState } from 'react';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Tabs,
  Tab,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';

import {
  toggleDialog,
  setAgencyFilter,
  setAgencyTypeFilter
} from '../../reducers/SettingsReducer';
import { setMode } from '../../reducers/ThemeReducer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh'
  },
  itemWrap: {
    fontWeight: 500
  },
  itemLabel: {
    fontWeight: 200
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function DialogSettings(props) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    isOpen,
    toggleDialog,
    themeMode,
    setMode,
    agencies,
    filterAgencies,
    filterAgenciesTypes,
    setAgencyFilter,
    setAgencyTypeFilter
  } = props;

  const [tab, setTab] = useState(0);

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    toggleDialog();
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleThemeModeChange = event => {
    setMode(event.target.value);
  };

  const handleAgencyChange = event => {
    setAgencyFilter(event);
  };

  const isAgencyChecked = agency => {
    const filter = filterAgencies;
    if (filter === null) {
      return true;
    } else {
      if (filter.includes(agency)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleAgencyTypeChange = event => {
    const filter = filterAgenciesTypes;
    switch (filter) {
      case 0:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(1);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(2);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(4);
        }
        break;
      case 1:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(0);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(3);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(5);
        }
        break;
      case 2:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(3);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(0);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(4);
        }
        break;
      case 3:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(2);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(1);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(7);
        }
        break;
      case 4:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(5);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(6);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(0);
        }
        break;
      case 5:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(4);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(7);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(1);
        }
        break;
      case 6:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(7);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(4);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(2);
        }
        break;
      case 7:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(6);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(5);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(3);
        }
        break;
      default:
      // default
    }
  };

  const isAgencyTypeChecked = agencyType => {
    /*
      Nothing checked => 0
      Police => 1
      Fire => 2
      Police + Fire => 3
      Ems => 4
      Police + Ems => 5
      Fire + Ems => 6
      All checked => 7
    */
    const filter = filterAgenciesTypes;
    switch (filter) {
      case 0:
        return false;
      case 1:
        if (agencyType === 'police') {
          return true;
        } else {
          return false;
        }
      case 2:
        if (agencyType === 'fire') {
          return true;
        } else {
          return false;
        }
      case 3:
        if (agencyType === 'fire') {
          return true;
        } else if (agencyType === 'police') {
          return true;
        } else {
          return false;
        }
      case 4:
        if (agencyType === 'ems') {
          return true;
        } else {
          return false;
        }
      case 5:
        if (agencyType === 'police') {
          return true;
        } else if (agencyType === 'ems') {
          return true;
        } else {
          return false;
        }
      case 6:
        if (agencyType === 'fire') {
          return true;
        } else if (agencyType === 'ems') {
          return true;
        } else {
          return false;
        }
      case 7:
        return true;
      default:
      // default
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={isOpen}
      onClose={handleClose}
      scroll="paper"
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth={'xs'}>
      <DialogTitle
        style={{ padding: '10px 0px 0px 0px' }}
        id="scroll-dialog-title">
        <Tabs
          value={tab}
          variant="scrollable"
          scrollButtons="on"
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="person details tabs">
          <Tab label="Theme mode" />
          <Tab label="Data filters" />
        </Tabs>
      </DialogTitle>
      <DialogContent
        dividers={true}
        className={classes.body}
        id="settings-dialog">
        <TabPanel value={tab} index={0}>
          <FormLabel component="legend">Select theme mode</FormLabel>
          <RadioGroup
            aria-label="theme-mode"
            name="Theme mode"
            value={themeMode}
            onChange={handleThemeModeChange}>
            <FormControlLabel value="day" control={<Radio />} label="Day" />
            <FormControlLabel value="night" control={<Radio />} label="Night" />
          </RadioGroup>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Agencies</FormLabel>
            <FormGroup onChange={event => handleAgencyChange(event)}>
              {agencies.map(agency => {
                return (
                  <FormControlLabel
                    key={agency.AgencyID}
                    control={
                      <Checkbox
                        checked={isAgencyChecked(agency.AgencyID)}
                        name={agency.AgencyID}
                      />
                    }
                    label={agency.Description}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
          <div className="divider my-1" />
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Agencies Types</FormLabel>
            <FormGroup onChange={event => handleAgencyTypeChange(event)}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAgencyTypeChecked('police')}
                    name="police"
                  />
                }
                label="Police"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={isAgencyTypeChecked('fire')} name="fire" />
                }
                label="Fire"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={isAgencyTypeChecked('ems')} name="ems" />
                }
                label="EMS"
              />
            </FormGroup>
          </FormControl>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  isOpen: state.settings.isOpen,
  themeMode: state.theme.mode,
  agencies: state.data.agencies,
  filterAgencies: state.settings.agencies,
  filterAgenciesTypes: state.settings.agencyTypes
});

export default connect(mapStateToProps, {
  toggleDialog,
  setMode,
  setAgencyFilter,
  setAgencyTypeFilter
})(DialogSettings);
