import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { Hidden, Drawer, List, ListItem, IconButton } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

import { makeStyles } from '@material-ui/core/styles';

import { FaMapMarkerAlt } from 'react-icons/fa';
import { RiPinDistanceLine } from 'react-icons/ri';

import { setEventsDrawerDesktopState } from '../../reducers/UiReducer';
import { setEventInfowindow } from '../../reducers/MapReducer';

import { getEventColor } from '../../utils/uiUtils';
import { getUnitColor } from '../../utils/uiUtils';

const drawerWidth = 240;
const drawerHeight = 80;

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    },
    zIndex: 98
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.bgElements,
    padding: '0px 5px'
  },
  toolbar: {
    height: drawerHeight,
    backgroundColor: theme.palette.bgElements
  },
  topHeaderBar: {
    position: 'fixed',
    backgroundColor: theme.palette.bgElements,
    zIndex: 99
  }
}));

const DrawerEventsDesktop = props => {
  const {
    state,
    setEventsDrawerDesktopState,
    events,
    themeMode,
    setEventInfowindow,
    eventInfowindow
  } = props;

  const classes = useStyles();

  const onEventClick = event => {
    setEventInfowindow(true, event);
  };

  const renderEvent = event => {
    if (themeMode === 'day') {
      let border = 'none';
      if (
        eventInfowindow.open === true &&
        eventInfowindow.data &&
        eventInfowindow.data.ptsEventID === event.ptsEventID
      ) {
        border = '2px solid rgba(0, 0, 0, 0.87)';
      }
      return (
        <ListItem
          button
          style={{
            border: border,
            color: '#fff',
            fontSize: '0.9rem',
            backgroundColor: getEventColor(event.Status),
            marginBottom: '5px',
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '5px',
            paddingRight: '5px'
          }}
          key={event.ptsEventID}
          onClick={() => onEventClick(event)}>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between'
              }}>
              <div>
                <span style={{ fontWeight: 'bold' }}>
                  {' '}
                  <FaMapMarkerAlt size={18} color="#fff" /> {event.EventID}
                </span>
              </div>
              <div>
                <span>{event.Status}</span>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between'
              }}>
              <div>
                <span style={{ fontStyle: 'italic' }}>
                  {moment(event.ReceiveDate).format('MM/DD/YYYY, h:mm a')}
                </span>
              </div>
              <div>
                <span>{event.CallType}</span>
              </div>
            </div>
            {event.distance && (
              <div>
                <span style={{ fontStyle: 'italic' }}>
                  <RiPinDistanceLine size={18} color="#fff" />
                  {event.distance.toFixed(2)}
                </span>
              </div>
            )}
            <div className="divider my-1" />
            <div>
              <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                Address
              </div>
              <div style={{ fontSize: '0.8rem' }}>
                {event.FullLocationAddress}
              </div>
            </div>
            {event.assignedUnits.length > 0 && (
              <Fragment>
                <div className="divider my-1" />
                <span style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
                  Units on Event
                </span>
                <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                  {event.assignedUnits.map((unit, index) => (
                    <li key={unit.ptsUnitID} style={{ marginBottom: '5px' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontSize: '0.8rem',
                          lineHeight: '1px'
                        }}>
                        <div style={{ alignSelf: 'center', lineHeight: '1px' }}>
                          {unit.Unit !== null ? unit.Unit : unit.ptsUnitID}
                        </div>
                        <div>
                          <span
                            className="badge"
                            style={{
                              backgroundColor: getUnitColor(unit.Path),
                              marginLeft: '5px'
                            }}>
                            {unit.UnitStatus}
                          </span>
                        </div>
                      </div>
                      <div style={{ lineHeight: '1px' }}>
                        <span
                          style={{
                            fontStyle: 'italic',
                            fontSize: '0.7rem',
                            paddingRight: '3px'
                          }}>
                          {moment(unit.Occurred).format('MM/DD/YYYY, h:mm a')}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </Fragment>
            )}
          </div>
        </ListItem>
      );
    } else {
      let background = 'transparent';
      let borderColor = getEventColor(event.Status);
      if (
        eventInfowindow.open === true &&
        eventInfowindow.data &&
        eventInfowindow.data.ptsEventID === event.ptsEventID
      ) {
        background = getEventColor(event.Status);
        borderColor = '#fff';
      }
      return (
        <ListItem
          button
          style={{
            background: background,
            color: '#fff',
            fontSize: '0.9rem',
            border: `1px solid ${getEventColor(event.Status)}`,
            marginBottom: '5px',
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '5px',
            paddingRight: '5px'
          }}
          key={event.ptsEventID}
          onClick={() => onEventClick(event)}>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between'
              }}>
              <div>
                <span style={{ fontWeight: 'bold' }}>
                  {' '}
                  <FaMapMarkerAlt
                    size={18}
                    color={getEventColor(event.Status)}
                  />{' '}
                  {event.EventID}
                </span>
              </div>
              <div>
                <span style={{ color: borderColor }}>{event.Status}</span>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between'
              }}>
              <div>
                <span style={{ fontStyle: 'italic' }}>
                  {moment(event.ReceiveDate).format('MM/DD/YYYY, h:mm a')}
                </span>
              </div>
              <div>
                <span>{event.CallType}</span>
              </div>
            </div>
            {event.distance && (
              <div>
                <span style={{ fontStyle: 'italic' }}>
                  <RiPinDistanceLine
                    size={18}
                    color={getEventColor(event.Status)}
                  />
                  {event.distance.toFixed(2)}
                </span>
              </div>
            )}
            <div style={{ background: borderColor }} className="divider my-1" />
            <div>
              <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                Address
              </div>
              <div style={{ fontSize: '0.8rem' }}>
                {event.FullLocationAddress}
              </div>
            </div>
            {event.assignedUnits.length > 0 && (
              <Fragment>
                <div
                  style={{ background: borderColor }}
                  className="divider my-1"
                />
                <span style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
                  Units on Event
                </span>
                <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                  {event.assignedUnits.map((unit, index) => (
                    <li key={unit.ptsUnitID} style={{ marginBottom: '10px' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontSize: '0.8rem',
                          lineHeight: '1px'
                        }}>
                        <div style={{ alignSelf: 'center' }}>
                          {unit.Unit !== null ? unit.Unit : unit.ptsUnitID}
                        </div>
                        <div>
                          <span
                            className="badge"
                            style={{
                              backgroundColor: getUnitColor(unit.Path)
                            }}>
                            {unit.UnitStatus}
                          </span>
                        </div>
                      </div>
                      <div style={{ lineHeight: '1px' }}>
                        <span
                          style={{
                            fontStyle: 'italic',
                            fontSize: '0.7rem',
                            paddingRight: '3px'
                          }}>
                          {moment(unit.Occurred).format('MM/DD/YYYY, h:mm a')}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </Fragment>
            )}
          </div>
        </ListItem>
      );
    }
  };

  const renderEvents = () => {
    if (events && events.length > 0) {
      return (
        <List style={{ paddingTop: '50px' }}>
          {events.map((event, index) => renderEvent(event))}
        </List>
      );
    } else {
      return (
        <Fragment>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </Fragment>
      );
    }
  };

  const drawer = (
    <div>
      <div className={classes.topHeaderBar}>
        <div className="divider my-1" style={{ background: '#ac0a26' }} />
        <div
          className="text-center"
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              alignSelf: 'center',
              color: '#ac0a26',
              fontSize: '1.1rem'
            }}
            className="font-weight-bold font-size-md mb-0">
            Events
          </div>
          <IconButton
            style={{ color: '#ac0a26' }}
            onClick={() => setEventsDrawerDesktopState()}
            size="small"
            className="btn-inverse">
            <MenuOpenRoundedIcon />
          </IconButton>
        </div>
        <div className="divider my-1" style={{ background: '#ac0a26' }} />
      </div>
      {renderEvents()}
    </div>
  );

  return (
    <Fragment>
      <Hidden smDown>
        <div
          className={classes.drawerPaper}
          style={{
            position: 'absolute',
            top: 80,
            left: 0,
            zIndex: 99
          }}>
          <div className="divider my-1" style={{ background: '#ac0a26' }} />
          <div
            className="text-center MuiPaper-root"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: 'transparent'
            }}>
            <div
              style={{
                alignSelf: 'center',
                color: '#ac0a26',
                fontSize: '1.1rem'
              }}
              className="font-weight-bold font-size-md mb-0">
              Events
            </div>
            <IconButton
              style={{ color: '#ac0a26' }}
              onClick={() => setEventsDrawerDesktopState()}
              size="small"
              className="btn-inverse">
              <MenuRoundedIcon />
            </IconButton>
          </div>
          <div className="divider my-1" style={{ background: '#ac0a26' }} />
        </div>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          open={state}
          anchor="left"
          classes={{
            paper: classes.drawerPaper
          }}>
          <div>
            <div className={classes.toolbar} />
            {drawer}
            {/*<div className={classes.toolbar} />*/}
          </div>
        </Drawer>
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  state: state.ui.eventsDrawerDesktopState,
  events: state.events.events,
  themeMode: state.theme.mode,
  eventInfowindow: state.map.eventInfowindow
});

export default connect(mapStateToProps, {
  setEventsDrawerDesktopState,
  setEventInfowindow
})(DrawerEventsDesktop);
