import store from '../config/configureStore';
import { setUserAuthenticated, setUserData } from './UserReducer';

export const SET_CODE_SEX = 'CODE/SET_CODE_SEX';
export const SET_CODE_RACE = 'CODE/SET_CODE_RACE';
export const SET_CODE_STATE = 'CODE/SET_CODE_STATE';

export const getCodeSex = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codesex');
      service.timeout = 20000;
      const codes = await service.find();
      dispatch({ type: SET_CODE_SEX, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      }
    }
  };
};

export const getCodeRace = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('coderace');
      service.timeout = 20000;
      const codes = await service.find();
      dispatch({ type: SET_CODE_RACE, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      }
    }
  };
};

export const getCodeState = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codestates');
      service.timeout = 20000;
      const states = await service.find({
        query: {
          $limit: 100
        }
      });
      dispatch({ type: SET_CODE_STATE, payload: states.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      }
    }
  };
};

export default function reducer(
  state = {
    sexCodes: [],
    raceCodes: [],
    statesCodes: []
  },
  action
) {
  switch (action.type) {
    case SET_CODE_SEX:
      return {
        ...state,
        sexCodes: action.payload
      };
    case SET_CODE_RACE:
      return {
        ...state,
        raceCodes: action.payload
      };
    case SET_CODE_STATE:
      return {
        ...state,
        statesCodes: action.payload
      };
    default:
      break;
  }
  return state;
}
