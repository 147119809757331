import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formSection: {
    paddingBottom: '10px'
  }
}));

const StatusFilter = props => {
  const classes = useStyles();

  const handleFilterStatusChange = event => {
    props.setSelectedStatus(event.target.value);
  };

  return (
    <FormControl fullWidth variant="outlined" className={classes.formSection}>
      <InputLabel id="filter-status">Status</InputLabel>
      <Select
        className={classes.selectInput}
        labelId="filter-status"
        value={props.selectedStatus}
        onChange={handleFilterStatusChange}>
        <MenuItem value=""> </MenuItem>
        {props.statusList.map((code, idx) => (
          <MenuItem value={code.Code} key={idx}>
            {code.Description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatusFilter;
