import store from '../config/configureStore';
import { enqueueSnackbar } from './NotifierReducer';

// User
export const USER_SET_AUTHENTICATED = 'USER/SET_AUTHENTICATED';
export const USER_SET_USER_UNITS = 'USER/SET_USER_UNITS';
export const USER_SET_DATA = 'USER/SET_DATA';
export const USER_IS_PASSWORD_VALID = 'USER/USER_IS_PASSWORD_VALID';
export const USER_SET_USER_UNIT = 'USER/USER_SET_USER_UNIT';
export const USER_SET_LOCATION = 'USER/SET_LOCATION';

export const validateCredentials = (username, password, isToken = false) => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      let user;
      if (!client) {
        console.log('Connection not initiated yet');
      } else if (isToken) {
        user = await client.reAuthenticate();
      } else {
        user = await client.authenticate({
          strategy: 'local',
          username: username,
          password: password
        });
      }

      const units = await client.service('availableunits').find({
        query: { username: user.user.Username }
      });

      dispatch({ type: USER_SET_USER_UNITS, payload: units });
      dispatch({ type: USER_SET_DATA, payload: user });
      dispatch({ type: USER_IS_PASSWORD_VALID, payload: true });
      if (units.length === 1) {
        dispatch({ type: USER_SET_USER_UNIT, payload: units[0] });
        dispatch({ type: USER_SET_AUTHENTICATED, payload: true });
        const service = client.service('units');
        service.timeout = 20000;
        await service.update(units[0].ptsUnitID, {
          login: 'login'
        });
      }
      if (units.length === 0) {
        dispatch({ type: USER_SET_USER_UNITS, payload: [] });
        dispatch({ type: USER_SET_DATA, payload: null });
        dispatch({ type: USER_IS_PASSWORD_VALID, payload: false });
        client.logout();
        dispatch(
          enqueueSnackbar({
            message:
              'There are no units assigned. Please contact administrator and try again.',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            }
          })
        );
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'Login failed. Please try again.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          }
        })
      );
      dispatch({ type: USER_IS_PASSWORD_VALID, payload: false });
      dispatch({ type: USER_SET_AUTHENTICATED, payload: false });
    }
  };
};

export const setUserUnitAndLogin = unit => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    dispatch({ type: USER_SET_USER_UNIT, payload: unit });
    dispatch({ type: USER_SET_AUTHENTICATED, payload: true });
    const service = client.service('units');
    service.timeout = 20000;
    await service.update(unit.ptsUnitID, {
      login: 'login'
    });
  };
};

export const getAvailableUserUnits = user => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const units = await client.service('availableunits').get(user);
      dispatch({ type: USER_SET_USER_UNITS, payload: units });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message:
            'Something went wrong when retrieving user units. Please try again.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          }
        })
      );
    }
  };
};

export const setUserAuthenticated = state => {
  const client = store.store.getState().websocket.websocket;
  const myUnit = store.store.getState().user.userUnit;
  return async dispatch => {
    if (client) {
      if (state === false) {
        const service = client.service('units');
        service.timeout = 20000;
        await service.update(myUnit.ptsUnitID, {
          login: 'logout'
        });
        client.logout();
      }
    }
    dispatch({ type: USER_SET_AUTHENTICATED, payload: state });
  };
};

export const setIsPasswordValid = state => {
  return dispatch => {
    dispatch({ type: USER_IS_PASSWORD_VALID, payload: state });
  };
};

export const setUserData = data => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    if (data !== null) {
      const _unit = store.store.getState().user.userUnit;
      if (_unit !== null) {
        const unit = await client.service('units').get(_unit.ptsUnitID);
        dispatch({ type: USER_SET_USER_UNIT, payload: unit[0] });
      }
    }
    dispatch({ type: USER_SET_DATA, payload: data });
  };
};

export const setUserUnits = units => {
  return dispatch => {
    dispatch({ type: USER_SET_USER_UNITS, payload: units });
  };
};

export const setUserUnit = unit => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    if (unit) {
      dispatch({ type: USER_SET_USER_UNIT, payload: unit });
    } else {
      const unitID = store.store.getState().user.userUnit.ptsUnitID;
      const unit = await client.service('units').get(unitID);
      dispatch({ type: USER_SET_USER_UNIT, payload: unit[0] });
    }
  };
};

export const newUserLocation = location => {
  const client = store.store.getState().websocket.websocket;
  const myUnit = store.store.getState().user.userUnit;

  return async dispatch => {
    // Make API call to store location in database
    if (client && myUnit && myUnit.ptsUnitID) {
      await client.service('unit-location').patch(myUnit.ptsUnitID, {
        LatitudeSign: location.latitude < 0 ? '-' : '+',
        LongitudeSign: location.longitude < 0 ? '-' : '+',
        LatitudeDegree: Math.abs(location.latitude),
        LongitudeDegree: Math.abs(location.longitude)
      });
    }

    dispatch({ type: USER_SET_LOCATION, payload: location });
  };
};

export default function reducer(
  state = {
    // User
    isAuthenticated: false,
    isPasswordValid: false,
    userUnits: [],
    userUnit: null,
    userData: null,
    location: null
  },
  action
) {
  switch (action.type) {
    // User
    case USER_SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload
      };
    case USER_SET_USER_UNITS:
      return {
        ...state,
        userUnits: action.payload
      };
    case USER_SET_DATA:
      return {
        ...state,
        userData: action.payload
      };
    case USER_IS_PASSWORD_VALID:
      return {
        ...state,
        isPasswordValid: action.payload
      };
    case USER_SET_USER_UNIT:
      return {
        ...state,
        userUnit: action.payload
      };
    case USER_SET_LOCATION:
      return {
        ...state,
        location: action.payload
      };
    default:
      break;
  }
  return state;
}
