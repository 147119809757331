import React, { Fragment } from 'react';

import SearchPerson from '../../components/SearchPerson';
import { Paper, Tabs, Tab } from '@material-ui/core';

const PagesSearch = props => {
  const handleTabChange = (event, newValue) => {
    console.log(newValue);
    //setEventInfowindowTab(newValue);
  };

  return (
    <Fragment>
      <Paper square className="app-page-title pts-page-title">
        <Tabs
          value={0}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="secondary"
          centered={true}
          variant="fullWidth"
          aria-label="search tabs">
          <Tab label="People" />
        </Tabs>
      </Paper>
      <Paper square className="m-3 p-3">
        <SearchPerson />
      </Paper>
    </Fragment>
  );
};

export default PagesSearch;
