// Map

export const SET_MAP = 'MAP/SET_MAP';
export const SET_MAP_CENTER = 'MAP/SET_MAP_CENTER';
export const SET_MAP_ZOOM = 'MAP/SET_MAP_ZOOM';
export const SET_EVENT_INFOWINDOW = 'MAP/SET_EVENT_INFOWINDOW';
export const SET_EVENT_INFOWINDOW_TAB = 'MAP/SET_EVENT_INFOWINDOW_TAB';
export const SET_UNIT_INFOWINDOW = 'MAP/SET_UNIT_INFOWINDOW';

export const setMap = (map, units = [], events = []) => {
  return async dispatch => {
    const bounds = new window.google.maps.LatLngBounds();

    if (units.length !== 0) {
      units.map(unit => {
        const latitude = `${
          unit.UnitLatitudeSign === '-' ? unit.UnitLatitudeSign : ''
        }${unit.UnitLatitudeDegree}`;
        const longitude = `${
          unit.UnitLongitudeSign === '-' ? unit.UnitLongitudeSign : ''
        }${unit.UnitLongitudeDegree}`;

        const position = {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude)
        };
        bounds.extend(position);
      });

      map.fitBounds(bounds);
    }

    if (events.length !== 0) {
      events.map(event => {
        const latitude = `${
          event.LatitudeSign === '-' ? event.LatitudeSign : ''
        }${event.LatitudeDegree}`;
        const longitude = `${
          event.LongitudeSign === '-' ? event.LongitudeSign : ''
        }${event.LongitudeDegree}`;

        const position = {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude)
        };
        bounds.extend(position);
      });

      map.fitBounds(bounds);
    }

    dispatch({ type: SET_MAP, payload: map });
  };
};

export const setEventInfowindow = (state, event) => {
  return async dispatch => {
    dispatch({
      type: SET_UNIT_INFOWINDOW,
      payload: { open: false, data: null }
    });
    if (event !== null) {
      if (event.LatitudeDegree !== null && event.LongitudeDegree !== null) {
        const latitude = `${
          event.LatitudeSign === '-' ? event.LatitudeSign : ''
        }${event.LatitudeDegree}`;
        const longitude = `${
          event.LongitudeSign === '-' ? event.LongitudeSign : ''
        }${event.LongitudeDegree}`;
        const position = {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude)
        };
        dispatch({
          type: SET_EVENT_INFOWINDOW,
          payload: { open: state, data: event }
        });
        dispatch({ type: SET_MAP_CENTER, payload: position });
        dispatch({ type: SET_MAP_ZOOM, payload: 15 });
      } else {
        dispatch({
          type: SET_EVENT_INFOWINDOW,
          payload: { open: state, data: event }
        });
      }
    } else {
      dispatch({
        type: SET_EVENT_INFOWINDOW,
        payload: { open: state, data: event }
      });
    }
  };
};

export const setEventInfowindowTab = tab => {
  return async dispatch => {
    dispatch({ type: SET_EVENT_INFOWINDOW_TAB, payload: tab });
  };
};

export const setUnitInfowindow = (state, unit) => {
  return async dispatch => {
    dispatch({
      type: SET_EVENT_INFOWINDOW,
      payload: { open: false, data: null }
    });
    if (unit !== null) {
      if (
        unit.UnitLatitudeDegree !== null &&
        unit.UnitLongitudeDegree !== null
      ) {
        const latitude = `${
          unit.UnitLatitudeSign === '-' ? unit.UnitLatitudeSign : ''
        }${unit.UnitLatitudeDegree}`;
        const longitude = `${
          unit.UnitLongitudeSign === '-' ? unit.UnitLongitudeSign : ''
        }${unit.UnitLongitudeDegree}`;
        const position = {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude)
        };
        dispatch({
          type: SET_UNIT_INFOWINDOW,
          payload: { open: state, data: unit }
        });
        dispatch({ type: SET_MAP_CENTER, payload: position });
        dispatch({ type: SET_MAP_ZOOM, payload: 15 });
      } else {
        dispatch({
          type: SET_UNIT_INFOWINDOW,
          payload: { open: state, data: unit }
        });
      }
    } else {
      dispatch({
        type: SET_UNIT_INFOWINDOW,
        payload: { open: state, data: unit }
      });
    }
  };
};

export default function reducer(
  state = {
    // Map
    map: null,
    mapcenter: { lat: 32.779167, lng: -96.808891 },
    mapzoom: 6,
    eventInfowindow: { open: false, data: null },
    eventInfowindowTab: 0,
    unitInfowindow: { open: false, data: null }
  },
  action
) {
  switch (action.type) {
    // Map
    case SET_MAP:
      return {
        ...state,
        map: action.payload
      };
    case SET_MAP_CENTER:
      return {
        ...state,
        mapcenter: action.payload
      };
    case SET_MAP_ZOOM:
      return {
        ...state,
        mapzoom: action.payload
      };
    case SET_EVENT_INFOWINDOW:
      return {
        ...state,
        eventInfowindow: action.payload
      };
    case SET_EVENT_INFOWINDOW_TAB:
      return {
        ...state,
        eventInfowindowTab: action.payload
      };
    case SET_UNIT_INFOWINDOW:
      return {
        ...state,
        unitInfowindow: action.payload
      };
    default:
      break;
  }
  return state;
}
