import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import Draggable from 'react-draggable';

import {
  Card,
  CardContent,
  Tabs,
  Tab,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Fab,
  Select,
  MenuItem,
  FormControl
} from '@material-ui/core';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FaWindowClose } from 'react-icons/fa';
import { RiPinDistanceLine } from 'react-icons/ri';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AttachmentList from './AttachmentList';

import {
  setEventInfowindow,
  setEventInfowindowTab
} from '../../../reducers/MapReducer';
import {
  setEventStatus,
  setEventNote,
  setEventDispositions,
  setEventAttachment
} from '../../../reducers/EventsReducer';

import { getEventColor } from '../../../utils/uiUtils';
import { getUnitColor } from '../../../utils/uiUtils';

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};

const DialogEvent = props => {
  const {
    network,
    dispositions,
    eventInfowindow,
    activeTab,
    setEventInfowindow,
    setEventInfowindowTab,
    setEventStatus,
    setEventNote,
    setEventDispositions,
    setEventAttachment
  } = props;

  const eventStatuses = [
    { status: 'Dispatch', value: 'Dispatched', disabled: true },
    { status: 'Enroute', value: 'Enroute', disabled: true },
    { status: 'Arrived', value: 'Arrived', disabled: true },
    { status: 'Completed', value: 'Completed', disabled: true },
    { status: 'Leave event', value: 'Close', disabled: true }
  ];

  const [isEventsFormVisible, setIsEventsFormVisible] = React.useState(true);
  const [notesFieldValue, setNotesFieldValue] = React.useState('');
  const [dispositionFieldValue, setDispositionsFieldValue] = React.useState([]);
  const [eventStatusValue, setEventStatusValue] = React.useState('Dispatched');
  const [eventStatusesList, setEventStatusesList] = React.useState(
    eventStatuses
  );
  const [attachmentFileName, setAttachmentFileName] = React.useState('');

  const handleEventInfoWindowClose = (state, infowindow) => {
    setNotesFieldValue('');
    setEventInfowindow(state, infowindow);
  };

  const handleTabChange = (event, newValue) => {
    setEventInfowindowTab(newValue);
  };

  const handleNotesChange = event => {
    setNotesFieldValue(event.target.value);
  };

  const handleDispositionChange = event => {
    setDispositionsFieldValue(event.target.value);
  };

  const submitNotesForm = e => {
    e.preventDefault();
    setEventNote(eventInfowindow.data, notesFieldValue);
    setNotesFieldValue('');
  };

  const submitDispositionsForm = e => {
    e.preventDefault();
    setEventDispositions(eventInfowindow.data, dispositionFieldValue);
    setDispositionsFieldValue([]);
  };

  const submitEventStatusForm = e => {
    e.preventDefault();
    setEventStatus(eventInfowindow.data, eventStatusValue);
  };

  const handleEventStatusChange = event => {
    setEventStatusValue(event.target.value);
  };

  const handleEventAttachmentChange = event => {
    setAttachmentFileName(event.target.files[0].name);
    setEventAttachment(eventInfowindow.data.ptsEventID, event.target.files[0]);
  };

  React.useEffect(() => {
    if (network.online === false) {
      // need to disable form submition
    } else {
      if (eventInfowindow.open === true && eventInfowindow.data) {
        if (eventInfowindow.data.myStatus) {
          if (
            eventInfowindow.data.myStatus ===
              'CAD.DefaultCodes.UnitActions.Dispatch' ||
            eventInfowindow.data.myStatus ===
              'CAD.DefaultCodes.UnitActions.Queued'
          ) {
            const list = [
              { status: 'Dispatch', value: 'DISPATCH', disabled: true },
              { status: 'Enroute', value: 'ENROUTE', disabled: false },
              { status: 'Arrived', value: 'ARRIVED', disabled: false },
              { status: 'Completed', value: 'COMPLETED', disabled: false },
              { status: 'Leave event', value: 'INSERVICE', disabled: false }
            ];
            setEventStatusesList(list);
            setEventStatusValue('ENROUTE');
            setIsEventsFormVisible(true);
          } else if (
            eventInfowindow.data.myStatus ===
            'CAD.DefaultCodes.UnitActions.Enroute'
          ) {
            const list = [
              { status: 'Dispatch', value: 'DISPATCH', disabled: true },
              { status: 'Enroute', value: 'ENROUTE', disabled: true },
              { status: 'Arrived', value: 'ARRIVED', disabled: false },
              { status: 'Completed', value: 'COMPLETED', disabled: false },
              { status: 'Leave event', value: 'INSERVICE', disabled: false }
            ];
            setEventStatusesList(list);
            setEventStatusValue('ARRIVED');
            setIsEventsFormVisible(true);
          } else if (
            eventInfowindow.data.myStatus ===
            'CAD.DefaultCodes.UnitActions.Arrived'
          ) {
            const list = [
              { status: 'Dispatch', value: 'DISPATCH', disabled: true },
              { status: 'Enroute', value: 'ENROUTE', disabled: true },
              { status: 'Arrived', value: 'ARRIVED', disabled: true },
              { status: 'Completed', value: 'COMPLETED', disabled: false },
              { status: 'Leave event', value: 'INSERVICE', disabled: false }
            ];
            setEventStatusesList(list);
            setEventStatusValue('COMPLETED');
            setIsEventsFormVisible(true);
          } else if (
            eventInfowindow.data.myStatus ===
            'CAD.DefaultCodes.UnitActions.Completed'
          ) {
            const list = [
              { status: 'Dispatch', value: 'DISPATCH', disabled: true },
              { status: 'Enroute', value: 'ENROUTE', disabled: true },
              { status: 'Arrived', value: 'ARRIVED', disabled: true },
              { status: 'Completed', value: 'COMPLETED', disabled: true },
              { status: 'Leave event', value: 'INSERVICE', disabled: false }
            ];
            setEventStatusesList(list);
            setEventStatusValue('INSERVICE');
            setIsEventsFormVisible(true);
          }
        } else {
          if (eventInfowindow.data.Status !== 'COMPLETED') {
            const list = [
              { status: 'Dispatch', value: 'DISPATCH', disabled: false },
              { status: 'Enroute', value: 'ENROUTE', disabled: false },
              { status: 'Arrived', value: 'ARRIVED', disabled: false },
              { status: 'Completed', value: 'COMPLETED', disabled: false },
              { status: 'Leave event', value: 'INSERVICE', disabled: false }
            ];
            setEventStatusesList(list);
            setEventStatusValue('DISPATCH');
            setIsEventsFormVisible(true);
          } else {
            const list = [
              { status: 'Dispatch', value: 'DISPATCH', disabled: true },
              { status: 'Enroute', value: 'ENROUTE', disabled: true },
              { status: 'Arrived', value: 'ARRIVED', disabled: true },
              { status: 'Completed', value: 'COMPLETED', disabled: true },
              { status: 'Leave event', value: 'INSERVICE', disabled: false }
            ];
            setEventStatusesList(list);
            setEventStatusValue('');
            setIsEventsFormVisible(false);
          }
        }
      }
    }
  }, [network, eventInfowindow]);
  if (eventInfowindow.open === true && eventInfowindow.data) {
    return (
      <Draggable handle=".handle" positionOffset={{ x: '-50%', y: '-50%' }}>
        <div
          className="pts-event-draggable"
          style={{
            position: 'absolute',
            zIndex: 999,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            padding: '0 5px'
          }}>
          <Card className="card-box pts-event-box">
            <FaWindowClose
              onClick={() => handleEventInfoWindowClose(false, null)}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: '3px',
                top: '3px',
                fontSize: '1.1rem'
              }}
            />
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-between'
                }}>
                <div
                  className="handle"
                  style={{ alignSelf: 'center', cursor: 'grab' }}>
                  <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                    <FaMapMarkerAlt
                      size={18}
                      color={getEventColor(eventInfowindow.data.Status)}
                    />{' '}
                    {eventInfowindow.data.EventID}
                  </span>
                </div>
                <div>
                  {eventInfowindow.data.distance && (
                    <span style={{ fontStyle: 'italic', paddingRight: '10px' }}>
                      <RiPinDistanceLine size={18} color="#fff" />
                      {eventInfowindow.data.distance.toFixed(2)}
                    </span>
                  )}
                  <span
                    className="badge"
                    style={{
                      backgroundColor: getEventColor(
                        eventInfowindow.data.Status
                      )
                    }}>
                    {eventInfowindow.data.Status}
                  </span>
                </div>
              </div>
              <div>
                <div style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                  {eventInfowindow.data.FullLocationAddress}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-between',
                  paddingTop: '5px'
                }}>
                <div style={{ alignSelf: 'center' }}>
                  <span style={{ fontStyle: 'italic' }}>
                    {moment(eventInfowindow.data.ReceiveDate).format(
                      'MM/DD/YYYY, h:mm a'
                    )}
                  </span>
                </div>
                <div>
                  <span>{eventInfowindow.data.CallType}</span>
                </div>
              </div>
              {isEventsFormVisible === true && (
                <div
                  style={{
                    position: 'fixed',
                    width: '100%',
                    bottom: '10px',
                    paddingRight: '55px'
                  }}>
                  <form
                    id="event-statuses-form"
                    onSubmit={submitEventStatusForm}
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <FormControl required fullWidth>
                      <Select
                        labelId="sevent-statuses-label"
                        id="event-statuses-select"
                        required
                        variant="outlined"
                        fullWidth
                        disabled={false}
                        style={{ paddingLeft: '60px' }}
                        value={eventStatusValue}
                        onChange={handleEventStatusChange}>
                        {eventStatusesList.map(e => {
                          return (
                            <MenuItem
                              key={e.status}
                              value={e.value}
                              disabled={e.disabled}>
                              {e.status}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <Fab
                        disabled={network.online === false ? true : false}
                        type="submit"
                        size="small"
                        style={{
                          position: 'absolute',
                          top: '5px',
                          left: '10px'
                        }}
                        color="primary">
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                      </Fab>
                    </FormControl>
                  </form>
                </div>
              )}
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="event tabs">
                <Tab label="Details" />
                <Tab label="Notes" />
                <Tab label="Dispositions" />
                <Tab label="Attachments" />
              </Tabs>
              <div
                className="card-indicator"
                style={{
                  backgroundColor: getEventColor(eventInfowindow.data.Status)
                }}
              />
              <TabPanel value={activeTab} index={0}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column'
                  }}>
                  <div style={{ minHeight: '140px' }}>
                    <div className="divider my-1" />
                    <div>
                      <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                        Caller
                      </div>
                      {eventInfowindow.data.callers.map(caller => {
                        return (
                          <Fragment key={caller.ptsPersonID}>
                            <div style={{ fontSize: '0.8rem' }}>
                              {caller.FullName} / {caller.Info}
                            </div>
                          </Fragment>
                        );
                      })}
                    </div>
                    <div className="divider my-1" />
                    {eventInfowindow.data.assignedUnits.length > 0 && (
                      <Fragment>
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '0.8rem'
                          }}>
                          Units on Event
                        </span>
                        <ul
                          style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                          {eventInfowindow.data.assignedUnits.map(
                            (unit, index) => (
                              <li key={unit.ptsUnitID}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    fontSize: '0.8rem',
                                    paddingBottom: '5px'
                                  }}>
                                  <div style={{ alignSelf: 'center' }}>
                                    {unit.Unit !== null
                                      ? unit.Unit
                                      : unit.ptsUnitID}
                                  </div>
                                  <div>
                                    <span style={{ fontStyle: 'italic' }}>
                                      {moment(unit.Occurred).format(
                                        'MM/DD/YYYY, h:mm a'
                                      )}
                                    </span>
                                    <span
                                      className="badge"
                                      style={{
                                        backgroundColor: getUnitColor(
                                          unit.Path
                                        ),
                                        marginLeft: '5px'
                                      }}>
                                      {unit.UnitStatus}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                        <div className="divider my-1" />
                      </Fragment>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    minHeight: 262,
                    maxHeight: 262,
                    overflow: 'auto'
                  }}>
                  <form id="notes-form" onSubmit={submitNotesForm}>
                    <TextField
                      className="mb-2 mt-2"
                      id="notes-input"
                      label="Notes"
                      fullWidth
                      required
                      multiline
                      rowsMax="3"
                      value={notesFieldValue}
                      onChange={handleNotesChange}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Fab
                              disabled={network.online === false ? true : false}
                              type="submit"
                              size="small"
                              color="primary">
                              <FontAwesomeIcon icon={['fas', 'plus']} />
                            </Fab>
                          </InputAdornment>
                        )
                      }}
                    />
                  </form>
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    {eventInfowindow.data.notes.map((note, index) => (
                      <Fragment key={note.ptsCommentID}>
                        <li>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              fontSize: '0.8rem'
                            }}>
                            <div
                              style={{
                                alignSelf: 'center',
                                fontStyle: 'italic',
                                fontWeight: 'bold'
                              }}>
                              {moment(note.Created).format(
                                'MM/DD/YYYY, h:mm a'
                              )}
                            </div>
                            <div>
                              <span
                                className="badge"
                                style={{ fontSize: '100%' }}>
                                {note.CreatedBy}
                              </span>
                            </div>
                          </div>
                          <div style={{ fontSize: '0.8rem' }}>
                            {note.Comment}
                          </div>
                        </li>
                        <div className="divider my-1" />
                      </Fragment>
                    ))}
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    minHeight: 262,
                    maxHeight: 262,
                    overflow: 'auto'
                  }}>
                  <form
                    id="dispositions-form"
                    onSubmit={submitDispositionsForm}
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <FormControl required fullWidth>
                      <Select
                        labelId="dispositions-label"
                        id="dispositions-select"
                        multiple
                        required
                        variant="outlined"
                        fullWidth
                        style={{ paddingLeft: '60px' }}
                        value={dispositionFieldValue}
                        onChange={handleDispositionChange}>
                        {dispositions.map(d => (
                          <MenuItem key={d.id} value={d.name}>
                            {d.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <Fab
                        disabled={network.online === false ? true : false}
                        type="submit"
                        size="small"
                        style={{
                          position: 'absolute',
                          top: '5px',
                          left: '10px'
                        }}
                        color="primary">
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                      </Fab>
                    </FormControl>
                  </form>
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    {eventInfowindow.data.dispositions.map(
                      (disposition, index) => (
                        <Fragment key={index}>
                          <li>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                fontSize: '0.8rem'
                              }}>
                              <div
                                style={{
                                  alignSelf: 'center',
                                  fontStyle: 'italic',
                                  fontWeight: 'bold'
                                }}>
                                {moment(disposition.Created).format(
                                  'MM/DD/YYYY, h:mm a'
                                )}
                              </div>
                              <div>
                                <span
                                  className="badge"
                                  style={{ fontSize: '100%' }}>
                                  {disposition.CreatedBy}
                                </span>
                              </div>
                            </div>
                            <div style={{ fontSize: '0.8rem' }}>
                              {disposition.Disposition}
                            </div>
                          </li>
                          <div className="divider my-1" />
                        </Fragment>
                      )
                    )}
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value={activeTab} index={3}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    minHeight: 262,
                    maxHeight: 262,
                    overflow: 'auto'
                  }}>
                  <TextField
                    className="mb-2 mt-2"
                    id="attachment-input"
                    label="Attachment"
                    fullWidth
                    disabled
                    value={attachmentFileName}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <input
                            onChange={handleEventAttachmentChange}
                            style={{
                              display: 'none'
                            }}
                            id="icon-button-file"
                            type="file"
                            capture
                          />
                          <label htmlFor="icon-button-file">
                            <Fab
                              className="MuiButtonBase-root MuiFab-root MuiFab-sizeSmall MuiFab-primary"
                              color="primary"
                              aria-label="upload picture"
                              component="span">
                              <FontAwesomeIcon icon={['fas', 'plus']} />
                            </Fab>
                          </label>
                        </InputAdornment>
                      )
                    }}
                  />

                  {eventInfowindow.data.Attachments && (
                    <ul style={{ listStyle: 'none', padding: 0 }}>
                      {JSON.parse(eventInfowindow.data.Attachments).map(
                        (attachment, i) => (
                          <AttachmentList info={attachment} key={i} />
                        )
                      )}
                    </ul>
                  )}
                </div>
              </TabPanel>
            </CardContent>
          </Card>
        </div>
      </Draggable>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => ({
  network: state.offline,
  dispositions: state.data.dispositions,
  unitActionCodes: state.data.unitActionCodes,
  eventInfowindow: state.map.eventInfowindow,
  activeTab: state.map.eventInfowindowTab
});

export default connect(mapStateToProps, {
  setEventInfowindow,
  setEventInfowindowTab,
  setEventStatus,
  setEventNote,
  setEventDispositions,
  setEventAttachment
})(DialogEvent);
