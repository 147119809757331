import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useLoadScript, GoogleMap } from '@react-google-maps/api';
import { mapNight, mapDay } from '../../config/configureMap';

import {
  Grid,
  Container,
  Card,
  CardContent,
  Button,
  FormControl,
  TextField,
  MenuItem
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import {
  setUserUnitAndLogin,
  validateCredentials,
  setIsPasswordValid,
  setUserUnits
} from '../../reducers/UserReducer';
import { setMode } from '../../reducers/ThemeReducer';
import { setMap } from '../../reducers/MapReducer';

import projectLogo from '../../assets/images/hero-image.png';
import projectLogoNight from '../../assets/images/hero-image-night.png';

const useStyles = makeStyles(theme => ({
  wrap: {
    backgroundColor: theme.palette.bgElements
  }
}));

const LoginForm = props => {
  const {
    themeMode,
    userUnits,
    setMap,
    isPasswordValid,
    setUserUnitAndLogin,
    validateCredentials,
    setIsPasswordValid,
    setUserUnits,
    websocket
  } = props;

  const classes = useStyles();
  const [username, setUsername] = React.useState('');
  const [unitId, setUnitId] = React.useState([]);
  const [password, setPassword] = React.useState('');
  const [init, setInit] = React.useState(false);

  React.useEffect(() => {
    const tokenLogin = async () => {
      if (
        websocket.websocket !== null &&
        window.location.search &&
        init === false
      ) {
        const token = window.location.search.split('=')[1];
        if (token) {
          const currentToken = localStorage.getItem('auth');
          if (currentToken !== token) {
            localStorage.setItem('auth', token);
          }
          setInit(true);
          await validateCredentials(null, null, true);
        }
      }
    };
    tokenLogin();
  }, [websocket, validateCredentials, init]);

  React.useEffect(() => {
    return () => {
      setIsPasswordValid(false);
      setUserUnits([]);
    };
  }, [setIsPasswordValid, setUserUnits]);

  const handleUsernameChange = event => {
    setUsername(event.target.value);
  };

  const handleUnitChange = event => {
    setUnitId(event.target.value);
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const submitForm = event => {
    event.preventDefault();
    if (isPasswordValid === false) {
      validateCredentials(username, password);
    } else {
      const unit = userUnits.filter(u => u.ptsUnitID === unitId);
      setUserUnitAndLogin(unit[0]);
    }
  };

  const mapOptions = {
    styles: themeMode === 'day' ? mapDay : mapNight,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: false
  };

  const center = { lat: 37.6, lng: -95.665 };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY
  });

  const mapOnLoad = map => {
    setMap(map);
  };

  if (isLoaded) {
    return (
      <Fragment>
        <div className={`app-wrapper min-vh-100 ${classes.wrap}`}>
          <div style={{ width: '100%', height: '100vh' }}>
            <GoogleMap
              id="map"
              center={center}
              zoom={5}
              options={mapOptions}
              mapContainerStyle={{
                height: '100%',
                width: '100%'
              }}
              onLoad={mapOnLoad}>
              <div className="app-main flex-column">
                <div className="app-content p-0">
                  <div
                    className="app-content--inner d-flex align-items-center"
                    style={{ padding: 0 }}>
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <div className="bg-composed-wrapper--content py-2">
                        <Container maxWidth="sm">
                          <Grid container spacing={5}>
                            <Grid
                              item
                              xs={12}
                              lg={12}
                              className="d-flex flex-column align-items-center">
                              <Card className="m-0 w-100 p-0 border-0">
                                <div className="card-header d-block m-0 p-0 rounded border-0">
                                  <div className="text-center mb-3">
                                    <img
                                      className="app-card-logo-img"
                                      style={{ width: '100%' }}
                                      alt="Cad Mobile"
                                      src={
                                        themeMode === 'day'
                                          ? projectLogo
                                          : projectLogoNight
                                      }
                                    />
                                  </div>
                                </div>
                                <CardContent className="p-3">
                                  <form onSubmit={submitForm}>
                                    {isPasswordValid === false && (
                                      <div className="mb-3">
                                        <FormControl className="w-100">
                                          <TextField
                                            required
                                            variant="outlined"
                                            label="Username"
                                            fullWidth
                                            placeholder="Enter your username"
                                            type="text"
                                            value={username}
                                            onChange={handleUsernameChange}
                                          />
                                        </FormControl>
                                      </div>
                                    )}
                                    {isPasswordValid === false && (
                                      <div className="mb-3">
                                        <FormControl className="w-100">
                                          <TextField
                                            required
                                            variant="outlined"
                                            label="Password"
                                            fullWidth
                                            placeholder="Enter your password"
                                            type="password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                          />
                                        </FormControl>
                                      </div>
                                    )}
                                    {isPasswordValid === true &&
                                      userUnits.length > 0 && (
                                        <div className="mb-3">
                                          <TextField
                                            fullWidth
                                            required
                                            id="units-select"
                                            select
                                            label="Select Unit"
                                            value={unitId}
                                            onChange={handleUnitChange}
                                            variant="outlined">
                                            {userUnits.map(unit => (
                                              <MenuItem
                                                key={unit.ptsUnitID}
                                                value={unit.ptsUnitID}>
                                                {unit.Unit}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        </div>
                                      )}
                                    {isPasswordValid === false && (
                                      <div className="text-center">
                                        <Button
                                          type="submit"
                                          color="primary"
                                          variant="contained"
                                          size="large"
                                          className="my-2">
                                          Login
                                        </Button>
                                      </div>
                                    )}
                                    {isPasswordValid === true &&
                                      userUnits.length > 0 && (
                                        <div className="text-center">
                                          <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            size="large"
                                            className="my-2">
                                            Select Unit
                                          </Button>
                                        </div>
                                      )}
                                  </form>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </GoogleMap>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className={`app-wrapper min-vh-100 ${classes.wrap}`}>
        <div className="app-main flex-column">
          <div className="app-content p-0">
            <div
              className="app-content--inner d-flex align-items-center"
              style={{ padding: 0 }}>
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-2">
                  <Container maxWidth="sm">
                    <Grid container spacing={5}>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        className="d-flex flex-column align-items-center">
                        <Card className="m-0 w-100 p-0 border-0">
                          <div className="card-header d-block m-0 p-0 rounded border-0">
                            <div className="text-center mb-3">
                              <img
                                className="app-card-logo-img"
                                style={{ width: '100%' }}
                                alt="Cad Mobile"
                                src={
                                  themeMode === 'day'
                                    ? projectLogo
                                    : projectLogoNight
                                }
                              />
                            </div>
                          </div>
                          <CardContent className="p-3">
                            <form onSubmit={submitForm}>
                              {isPasswordValid === false && (
                                <div className="mb-3">
                                  <FormControl className="w-100">
                                    <TextField
                                      required
                                      variant="outlined"
                                      label="Username"
                                      fullWidth
                                      placeholder="Enter your username"
                                      type="text"
                                      value={username}
                                      onChange={handleUsernameChange}
                                    />
                                  </FormControl>
                                </div>
                              )}
                              {isPasswordValid === false && (
                                <div className="mb-3">
                                  <FormControl className="w-100">
                                    <TextField
                                      required
                                      variant="outlined"
                                      label="Password"
                                      fullWidth
                                      placeholder="Enter your password"
                                      type="password"
                                      value={password}
                                      onChange={handlePasswordChange}
                                    />
                                  </FormControl>
                                </div>
                              )}
                              {isPasswordValid === true &&
                                userUnits.length > 0 && (
                                  <div className="mb-3">
                                    <TextField
                                      fullWidth
                                      required
                                      id="units-select"
                                      select
                                      label="Select Unit"
                                      value={unitId}
                                      onChange={handleUnitChange}
                                      variant="outlined">
                                      {userUnits.map(unit => (
                                        <MenuItem
                                          key={unit.ptsUnitID}
                                          value={unit.ptsUnitID}>
                                          {unit.Unit}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </div>
                                )}
                              {isPasswordValid === false && (
                                <div className="text-center">
                                  <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    className="my-2">
                                    Login
                                  </Button>
                                </div>
                              )}
                              {isPasswordValid === true && (
                                <div className="text-center">
                                  <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    className="my-2">
                                    Select Unit
                                  </Button>
                                </div>
                              )}
                            </form>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  isPasswordValid: state.user.isPasswordValid,
  userUnits: state.user.userUnits,
  websocket: state.websocket
});

export default connect(mapStateToProps, {
  validateCredentials,
  setUserUnitAndLogin,
  setMode,
  setMap,
  setIsPasswordValid,
  setUserUnits
})(LoginForm);
