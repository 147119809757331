import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formSection: {
    paddingBottom: '10px'
  }
}));

const EventFilter = props => {
  const classes = useStyles();

  const handleFilterEventChange = event => {
    props.setSelectedEvent(event.target.value);
  };

  React.useEffect(() => {
    if (
      props.eventList !== null &&
      props.eventList.length > 0 &&
      props.myEvents !== null &&
      props.myEvents.length > 0
    ) {
      const lastEvent = props.myEvents[props.myEvents.length - 1];
      if (lastEvent) {
        props.setSelectedEvent(lastEvent.ptsEventID);
      }
    }
  }, []);

  return (
    <FormControl fullWidth variant="outlined" className={classes.formSection}>
      <InputLabel id="filter-status">Event</InputLabel>
      <Select
        labelId="event-status"
        value={props.selectedEvent}
        onChange={handleFilterEventChange}>
        <MenuItem value=""> </MenuItem>
        {props.eventList.map((event, idx) => (
          <MenuItem value={event.ptsEventID} key={idx}>
            {event.EventID}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default EventFilter;
