import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { GoogleMap } from '@react-google-maps/api';

import MarkersEvents from './MarkersEvents';
import MarkersUnits from './MarkersUnits';

import { mapNight, mapDay } from '../../config/configureMap';
import { setMap } from '../../reducers/MapReducer';

const RenderMap = props => {
  const { themeMode, setMap, mapcenter, mapzoom, units, events } = props;

  const mapOptions = {
    styles: themeMode === 'day' ? mapDay : mapNight,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: false
  };

  const onLoad = React.useCallback(function onLoad(map) {
    setMap(map, units, events);
  });

  return (
    <Fragment>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}>
        <GoogleMap
          id="map"
          center={mapcenter}
          zoom={mapzoom}
          clickableIcons={false}
          options={mapOptions}
          mapContainerStyle={{
            height: '100%',
            width: '100%'
          }}
          onLoad={onLoad}>
          <MarkersEvents />
          <MarkersUnits />
        </GoogleMap>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  mapcenter: state.map.mapcenter,
  mapzoom: state.map.mapzoom,
  units: state.units.units,
  events: state.events.events,
  bounds: state.map.bounds
});

export default connect(mapStateToProps, { setMap })(RenderMap);
