import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import GetApp from '@material-ui/icons/GetApp';
import moment from 'moment-timezone';

import { removeEventAttachment } from '../../../reducers/EventsReducer';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    fontSize: '0.8rem'
  },
  date: {
    fontStyle: 'italic',
    margin: '0 10px'
  },
  fileName: {
    fontWeight: 'bold',
    textAlign: 'right'
  }
}));

export default function AttachmentList({ info }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { websocket: client } = useSelector(state => {
    return state.websocket;
  });

  const deleteButtonHandler = () => {
    dispatch(removeEventAttachment(info.ptsAttachmentID));
  };

  function bufferToBlob(buffer, mime) {
    return new Blob([buffer], { type: mime });
  }

  const downloadButtonHandler = async () => {
    const service = client.service('download-attachment');
    service.timeout = 15000;

    const res = await service.get(info.ptsAttachmentID);
    const blob = bufferToBlob(res.buffer, res.type);

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = info.FileName;
    link.click();
  };

  return (
    <Fragment key={info.ptsAttachmentID}>
      <li>
        <div className={classes.root}>
          <IconButton size="small" onClick={deleteButtonHandler}>
            <Delete fontSize="inherit" color="primary" />
          </IconButton>

          <IconButton size="small" onClick={downloadButtonHandler}>
            <GetApp fontSize="inherit" color="primary" />
          </IconButton>

          <div className={classes.date}>
            {moment(info.Attached).format('MM/DD/YYYY, h:mm a')}
          </div>

          <span className={classes.fileName}>{info.FileName}</span>
        </div>

        <div style={{ fontSize: '0.8rem' }}>{info.Description}</div>
      </li>

      <div className="divider my-1" />
    </Fragment>
  );
}
