import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Button,
  Hidden,
  Avatar,
  Box,
  Badge,
  Menu,
  List,
  ListItem,
  Divider
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GiPoliceOfficerHead } from 'react-icons/gi';
import { TiHome } from 'react-icons/ti';
import { FaSearch } from 'react-icons/fa';

import AboutDialog from '../AboutDialog';

import { makeStyles } from '@material-ui/core/styles';

import {
  setNavigationDrawerMobileState,
  setEventsDrawerMobileState,
  setUnitsDrawerMobileState
} from '../../reducers/UiReducer';
import { setMode } from '../../reducers/ThemeReducer';
import { setUserAuthenticated } from '../../reducers/UserReducer';
import { toggleDialog } from '../../reducers/SettingsReducer';
import { toggleDialog as ToggleUnitDialog } from '../../reducers/UnitStatusReducer';

import { getUnitColor } from '../../utils/uiUtils';

import { withStyles } from '@material-ui/core/styles';

const drawerHeight = 80;

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: 99,
    backgroundColor: theme.palette.bgElements
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  toolbar: {
    height: drawerHeight,
    backgroundColor: theme.palette.bgElements
  },
  username: {
    position: 'absolute',
    bottom: '-13px',
    left: '-5px',
    width: '100%',
    textAlign: 'center',
    fontSize: '0.7rem',
    fontWeight: 600,
    color: theme.palette.primary.main
  }
}));

const TopBar = props => {
  const {
    setNavigationDrawerMobileState,
    setEventsDrawerMobileState,
    setUnitsDrawerMobileState,
    setUserAuthenticated,
    network,
    user,
    toggleDialog,
    ToggleUnitDialog
  } = props;

  React.useEffect(() => {
    if (network.online === false) {
      setUnitStatusBtn(true);
    } else {
      if (user.userUnit) {
        if (
          user.userUnit.Path === 'CAD.DefaultCodes.UnitActions.Inservice' ||
          user.userUnit.Path === 'CAD.DefaultCodes.UnitActions.Outservice'
        ) {
          setUnitStatusBtn(false);
        } else {
          setUnitStatusBtn(true);
        }
      }
    }
  }, [network, user]);

  const [unitStatusBtn, setUnitStatusBtn] = React.useState(false);
  const [displayAbout, setDisplayAbout] = React.useState(false);

  let location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  const StyledBadge = withStyles({
    badge: {
      backgroundColor:
        network.online === false ? 'var(--danger)' : 'var(--success)',
      color: network.online === false ? 'var(--danger)' : 'var(--success)',
      boxShadow: '0 0 0 2px #fff',
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""'
      }
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0
      }
    }
  })(Badge);

  const handleUserMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = () => {
    setAnchorEl(null);
    toggleDialog();
  };

  const handleAboutClick = () => {
    setAnchorEl(null);
    setDisplayAbout(!displayAbout);
  };

  const handleUnitStatusClick = () => {
    setAnchorEl(null);
    ToggleUnitDialog();
  };

  return (
    <Fragment>
      <AppBar color="primary" position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Hidden mdUp>
            <div
              style={{
                display: 'flex',
                alignSelf: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}>
              <div style={{ alignSelf: 'center' }}>
                <IconButton
                  color="primary"
                  aria-label="Open drawer"
                  edge="start"
                  onClick={() => setNavigationDrawerMobileState()}>
                  <MenuIcon color="primary" />
                </IconButton>
              </div>
              <div style={{ display: 'flex' }}>
                {location.pathname === '/Main' && (
                  <Fragment>
                    <div>
                      <Tooltip title="Events">
                        <Button
                          style={{
                            width: '60px',
                            height: '60px'
                          }}
                          onClick={() => setEventsDrawerMobileState()}
                          variant="outlined"
                          color="primary"
                          className="p-0 m-2 d-block">
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon
                              icon="calendar-alt"
                              className="font-size-xxl"
                            />
                          </span>
                          <span
                            style={{
                              position: 'absolute',
                              bottom: -3,
                              left: 10,
                              fontWeight: 'bold'
                            }}>
                            Events
                          </span>
                        </Button>
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip title="Units">
                        <Button
                          style={{
                            width: '60px',
                            height: '60px'
                          }}
                          onClick={() => setUnitsDrawerMobileState()}
                          variant="outlined"
                          color="primary"
                          className="p-0 m-2 d-block">
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon
                              icon="car"
                              className="font-size-xxl"
                            />
                          </span>
                          <span
                            style={{
                              position: 'absolute',
                              bottom: -3,
                              left: 14,
                              fontWeight: 'bold'
                            }}>
                            Units
                          </span>
                        </Button>
                      </Tooltip>
                    </div>
                  </Fragment>
                )}
                <div style={{ alignSelf: 'center' }}>
                  <Button
                    color="primary"
                    onClick={handleUserMenuClick}
                    className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
                    <Box>
                      <StyledBadge
                        overlap="circle"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right'
                        }}
                        variant="dot">
                        {user.userUnit && user.userUnit !== null && (
                          <Badge
                            badgeContent={
                              <span
                                className="badge"
                                style={{
                                  backgroundColor: getUnitColor(
                                    user.userUnit.Path
                                  ),
                                  marginLeft: '5px',
                                  color: '#fff'
                                }}>
                                {user.userUnit.UnitStatus}
                              </span>
                            }>
                            <Avatar
                              sizes="44"
                              alt={
                                user.userData
                                  ? user.userData.user.Username
                                  : null
                              }
                            />
                          </Badge>
                        )}
                      </StyledBadge>
                      <div className={classes.username}>
                        {user.userData ? user.userData.user.Username : null}
                      </div>
                    </Box>
                    <div className="d-none d-xl-block">
                      <div className="font-weight-bold pt-2 line-height-1">
                        {user.userData ? user.userData.user.Username : null}
                      </div>
                      <span className="text-white-50">
                        {user.userUnit !== null ? user.userUnit.Unit : null}
                      </span>
                    </div>
                    <span className="pl-1 pl-xl-3">
                      <FontAwesomeIcon
                        style={{ color: '#ac0a26' }}
                        icon={['fas', 'angle-down']}
                        className="opacity-5"
                      />
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </Hidden>
          <Hidden smDown>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}>
              <div style={{ display: 'flex', flex: 1 }}>
                <Tooltip title="Activity">
                  <Link to="/Main">
                    <Button
                      style={{
                        width: '85px',
                        height: '60px'
                      }}
                      variant="outlined"
                      color="primary"
                      className="p-0 m-2 d-block">
                      <span className="btn-wrapper--icon">
                        <TiHome style={{ fontSize: '2.5rem' }} />
                      </span>
                      <span
                        style={{
                          position: 'absolute',
                          bottom: -3,
                          left: 23,
                          fontWeight: 'bold'
                        }}>
                        Home
                      </span>
                    </Button>
                  </Link>
                </Tooltip>
                <Tooltip title="Search">
                  <Link to="/Search">
                    <Button
                      style={{
                        width: '85px',
                        height: '60px'
                      }}
                      variant="outlined"
                      color="primary"
                      className="p-0 m-2 d-block">
                      <span className="btn-wrapper--icon">
                        <FaSearch style={{ fontSize: '1.9rem' }} />
                      </span>
                      <span
                        style={{
                          position: 'absolute',
                          bottom: -3,
                          left: 19,
                          fontWeight: 'bold'
                        }}>
                        Search
                      </span>
                    </Button>
                  </Link>
                </Tooltip>
              </div>
              <div style={{ alignSelf: 'center' }}>
                <Button
                  color="inherit"
                  onClick={handleUserMenuClick}
                  className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
                  <Box>
                    <StyledBadge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      variant="dot">
                      {user.userUnit && user.userUnit !== null && (
                        <Badge
                          badgeContent={
                            <span
                              className="badge"
                              style={{
                                backgroundColor: getUnitColor(
                                  user.userUnit.Path
                                ),
                                marginLeft: '5px'
                              }}>
                              {user.userUnit.UnitStatus}
                            </span>
                          }>
                          <Avatar
                            sizes="44"
                            alt={
                              user.userData ? user.userData.user.Username : null
                            }
                          />
                        </Badge>
                      )}
                    </StyledBadge>
                    <div className={classes.username}>
                      {user.userData ? user.userData.user.Username : null}
                    </div>
                  </Box>
                  <div className="d-none d-xl-block">
                    <div className="font-weight-bold pt-2 line-height-1">
                      {user.userData ? user.userData.user.Username : null}
                    </div>
                    <span className="text-white-50">
                      {user.userUnit !== null ? user.userUnit.Unit : null}
                    </span>
                  </div>
                  <span className="pl-1 pl-xl-3">
                    <FontAwesomeIcon
                      style={{ color: '#ac0a26' }}
                      icon={['fas', 'angle-down']}
                      className="opacity-5"
                    />
                  </span>
                </Button>
              </div>
            </div>
          </Hidden>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            open={Boolean(anchorEl)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            onClose={handleClose}
            className="ml-2">
            <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
              <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
                <Box>
                  <StyledBadge
                    overlap="circle"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    variant="dot">
                    {user.userUnit && user.userUnit !== null && (
                      <Badge
                        badgeContent={
                          <span
                            className="badge"
                            style={{
                              backgroundColor: getUnitColor(user.userUnit.Path),
                              marginLeft: '10px',
                              marginTop: '20px',
                              color: '#fff'
                            }}>
                            {user.userUnit.UnitStatus}
                          </span>
                        }>
                        <Avatar
                          sizes="44"
                          alt={
                            user.userData ? user.userData.user.Username : null
                          }
                        />
                      </Badge>
                    )}
                  </StyledBadge>
                </Box>
                <div>
                  <div className="font-weight-bold text-center pt-2 line-height-1">
                    {user.userData ? user.userData.user.Username : null}
                  </div>
                  <span className="text-black-50 text-center">
                    {user.userUnit !== null ? user.userUnit.Unit : null}
                  </span>
                </div>
                <Divider className="w-100 mt-2" />
                <ListItem onClick={() => handleSettingsClick()} button>
                  Settings
                </ListItem>
                <ListItem onClick={() => handleAboutClick()} button>
                  About
                </ListItem>
                <Divider className="w-100" />
                {/*
              <ListItem className="d-block text-center">
                <Button
                  fullWidth
                  disabled={unitStatusBtn}
                  onClick={() => handleUnitStatusChange()}
                  variant="contained"
                  size="medium"
                  color="primary">
                  <span className="btn-wrapper--icon">
                    <GiPoliceOfficerHead size={18} color="#fff" />
                  </span>
                  {user.userUnit !== null && (
                    <span className="btn-wrapper--label">
                      {user.userUnit.Path ===
                      'CAD.DefaultCodes.UnitActions.Outservice'
                        ? 'In Service'
                        : 'Off Duty'}
                    </span>
                  )}
                </Button>
              </ListItem>
              */}
                <ListItem className="d-block rounded-bottom pb-0 text-center">
                  <Button
                    fullWidth
                    onClick={() => handleUnitStatusClick()}
                    variant="contained"
                    size="medium"
                    color="primary">
                    <span className="btn-wrapper--icon">
                      <GiPoliceOfficerHead size={18} color="#fff" />
                    </span>
                    <span className="btn-wrapper--label">Status change</span>
                  </Button>
                </ListItem>
                <ListItem className="d-block rounded-bottom pb-0 text-center">
                  <Button
                    fullWidth
                    onClick={() => setUserAuthenticated(false)}
                    variant="contained"
                    size="medium"
                    color="primary">
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                    </span>
                    <span className="btn-wrapper--label">Logout</span>
                  </Button>
                </ListItem>
              </List>
            </div>
          </Menu>
        </Toolbar>
      </AppBar>
      {displayAbout && <AboutDialog close={handleAboutClick} />}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  network: state.offline,
  themeMode: state.theme.mode,
  user: state.user
});

export default connect(mapStateToProps, {
  setNavigationDrawerMobileState,
  setEventsDrawerMobileState,
  setUnitsDrawerMobileState,
  setMode,
  setUserAuthenticated,
  toggleDialog,
  ToggleUnitDialog
})(TopBar);
