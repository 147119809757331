import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Card } from '@material-ui/core';
//import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh',
    padding: '10px 10px'
  },
  itemWrap: {
    fontWeight: 500
  },
  itemLabel: {
    fontWeight: 200
  }
}));

function Address(props) {
  const { data, themeMode } = props;
  const classes = useStyles();

  if (data !== null) {
    return (
      <div className="table-responsive">
        <table
          className={clsx(
            'table',
            themeMode === 'night' && 'table-pts-night',
            themeMode === 'day' && 'table-pts-day',
            'text-nowrap',
            'mb-0'
          )}>
          <thead>
            <tr>
              <th className="bg-white">Address</th>
              <th className="bg-white text-center">Is Primary</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, idx) => {
              return (
                <tr key={idx}>
                  <td>{row.FullAddressText}</td>
                  <td className="text-center">
                    <div
                      className={clsx(
                        'badge',
                        row.IsPrimary === true &&
                          'bg-neutral-success text-success',
                        row.IsPrimary === false &&
                          'bg-neutral-danger text-danger'
                      )}>
                      {row.IsPrimary === true ? 'Yes' : 'No'}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <Card className="card-box bg-neutral-warning border-1 card-border-top border-warning text-center p-3">
        <h4 style={{ marginBottom: 0, color: 'black' }}>
          Sorry, no addresses found.
        </h4>
      </Card>
    );
  }
}

export default Address;
