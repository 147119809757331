import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formSection: {
    paddingBottom: '10px'
  }
}));

const PlateStateFilter = props => {
  const classes = useStyles();

  const handleFilterPlateStateChange = event => {
    props.setSelectedPlateState(event.target.value);
  };

  return (
    <FormControl fullWidth variant="outlined" className={classes.formSection}>
      <InputLabel id="filter-status">Plate State</InputLabel>
      <Select
        labelId="plate-state"
        value={props.selectedPlateState}
        onChange={handleFilterPlateStateChange}>
        <MenuItem value=""> </MenuItem>
        {props.states.map((state, idx) => (
          <MenuItem value={state.Code} key={idx}>
            {state.Description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PlateStateFilter;
