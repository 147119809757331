import store from '../config/configureStore';
import { setUserAuthenticated, setUserData } from './UserReducer';

export const SET_DISPOSITIONS = 'DATA/SET_DISPOSITIONS';
export const SET_AGENCIES = 'DATA/SET_AGENCIES';
export const SET_UNIT_ACTION_CODES = 'DATA/SET_UNIT_ACTION_CODES';

export const getDispositions = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('dispositions');
      service.timeout = 20000;
      const dispositions = await service.find();
      dispatch({ type: SET_DISPOSITIONS, payload: dispositions });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      }
    }
  };
};

export const getAgencies = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('ptsagencies');
      service.timeout = 20000;
      const agencies = await service.find();
      dispatch({ type: SET_AGENCIES, payload: agencies.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      }
    }
  };
};

export const getUnitActionCodes = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('unit-actions');
      service.timeout = 20000;
      const unitActionsCodes = await service.find();
      dispatch({ type: SET_UNIT_ACTION_CODES, payload: unitActionsCodes });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      }
    }
  };
};

export default function reducer(
  state = {
    // Dispositions
    dispositions: [],
    agencies: [],
    agenciesTypes: [],
    unitActionCodes: []
  },
  action
) {
  switch (action.type) {
    // Dispositions
    case SET_DISPOSITIONS:
      return {
        ...state,
        dispositions: action.payload
      };
    // Dispositions
    case SET_AGENCIES:
      return {
        ...state,
        agencies: action.payload
      };
    // Unit action codes
    case SET_UNIT_ACTION_CODES:
      return {
        ...state,
        unitActionCodes: action.payload
      };
    default:
      break;
  }
  return state;
}
