import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { OverlayView } from '@react-google-maps/api';
import { IoIosCar } from 'react-icons/io';

import { getUnitColor } from '../../../utils/uiUtils';
import { setUnitInfowindow } from '../../../reducers/MapReducer';

const positionOverlayView = (width, height) => ({
  x: -13,
  y: -26
});

const MarkersUnits = props => {
  const { units, setUnitInfowindow } = props;

  const onClick = unit => {
    setUnitInfowindow(true, unit);
  };

  const renderMarker = unit => {
    if (unit.UnitLatitudeDegree !== null && unit.UnitLongitudeDegree !== null) {
      const latitude = `${
        unit.UnitLatitudeSign === '-' ? unit.UnitLatitudeSign : ''
      }${unit.UnitLatitudeDegree}`;
      const longitude = `${
        unit.UnitLongitudeSign === '-' ? unit.UnitLongitudeSign : ''
      }${unit.UnitLongitudeDegree}`;
      const position = {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
      };
      return (
        <OverlayView
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          key={unit.ptsUnitID}
          position={position}
          getPixelPositionOffset={positionOverlayView}>
          <Fragment>
            <div
              style={{
                position: 'sticky',
                marginLeft: '-15px',
                border: '1px solid white',
                padding: 0,
                background: 'rgba(255,255,255,0.6)',
                borderTopRightRadius: 5,
                borderBottomLeftRadius: 5
              }}>
              {unit.Unit}
            </div>
            <IoIosCar
              style={{ cursor: 'pointer' }}
              onClick={() => onClick(unit)}
              size={26}
              color={getUnitColor(unit.Path)}
            />
          </Fragment>
        </OverlayView>
      );
    } else {
      return null;
    }
  };

  if (units.length > 0) {
    return units.map((unit, index) => renderMarker(unit));
  } else {
    return null;
  }
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  units: state.units.units
});

export default connect(mapStateToProps, { setUnitInfowindow })(MarkersUnits);
