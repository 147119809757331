import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import Spinner from '../../../global-components/Spinner';

import clsx from 'clsx';

const stylesDay = {
  spinner: {
    background: 'rgba(255, 255, 255, 0.5)'
  }
};

const stylesNight = {
  spinner: {
    background: 'rgba(0, 0, 0, 0.5)'
  }
};

const styles = themeMode => {
  return themeMode === 'day' ? stylesDay : stylesNight;
};

const useStyles = makeStyles(theme => ({
  tableWrap: {
    overflow: 'auto',
    margin: '0 auto',
    position: 'relative'
  },
  header: {
    cursor: 'pointer'
  },
  spinner: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: '#999'
    }
  }
}));

const Table = props => {
  const classes = useStyles();
  const { data, config, sort, themeMode } = props;
  const { orderBy, rowsPerPage, orderDirection, filters, loading } = data;
  const [headCells, setHeadCells] = useState(getHeadCells(data.rows, config));

  useEffect(() => {
    setHeadCells(getHeadCells(data.rows, config));
  }, [config, data]);

  const handlePageChange = (ev, pageNo) => {
    const options = {
      pageNo,
      filters,
      orderBy,
      rowsPerPage,
      orderDirection
    };
    props.getData(options);
  };

  const handleChangeRowsPerPage = (ev, info) => {
    const rowsPerPage = parseInt(info.key);
    const options = {
      pageNo: 0,
      filters,
      orderBy,
      rowsPerPage,
      orderDirection
    };
    props.getData(options);
  };

  const handleRowClick = row => {
    props.rowClick(row);
  };

  return (
    <Fragment>
      <div className="table-responsive">
        <table
          className={clsx(
            'table',
            'table-hover',
            themeMode === 'night' && 'table-pts-night',
            themeMode === 'day' && 'table-pts-day',
            'text-nowrap',
            'mb-0'
          )}>
          <thead>
            <tr>
              {headCells.map((row, idx) => {
                return (
                  <Fragment key={row.label}>
                    <th onClick={() => sort(row.dbName)} title={row.title}>
                      <span>
                        {row.label}
                        {row.dbName === orderBy && orderDirection === 'ASC' && (
                          <ArrowDownwardIcon />
                        )}
                        {row.dbName === orderBy &&
                          orderDirection === 'DESC' && <ArrowUpwardIcon />}
                      </span>
                    </th>
                  </Fragment>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.rows.map((row, idx) => {
              let cols = [];
              Object.entries(row).forEach(([key, val]) => {
                if (key !== 'key') {
                  if (config.display[key]) {
                    const dataType = config.display[key].type;
                    if (val && dataType === 'date' && moment(val).isValid()) {
                      cols.push({
                        text: moment(val).format('MM/DD/YYYY'),
                        seq: config.display[key].seq
                      });
                    } else {
                      cols.push({ text: val, seq: config.display[key].seq });
                    }
                  }
                }
              });
              if (cols.length > 0 && cols[0].seq !== undefined) {
                cols = cols.sort((a, b) => a.seq - b.seq);
              }
              return (
                <tr key={idx} onClick={() => handleRowClick(row)}>
                  {cols.map((obj, idx) => (
                    <Fragment key={idx}>
                      <td title={obj.text}>
                        <span>{obj.text}</span>
                      </td>
                    </Fragment>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        rowsPerPageOptions={config.pageSizeOptions}
        component="div"
        count={data.count}
        rowsPerPage={data.rowsPerPage}
        page={data.pageNo}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {loading && <Spinner />}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode
});

export default connect(mapStateToProps)(Table);

// ======================= HELPER FUNCTIONS ====================

function getHeadCells(dataObj, config) {
  const result = [];
  if (dataObj.length === 0) return [];
  const row = dataObj[0];
  Object.keys(row).forEach(key => {
    const option = config.display[key];
    if (option) {
      if (key !== 'key') {
        result.push({
          name: key,
          dbName: option.dbName,
          label: option ? option.label : key,
          width: getColWidth(key, config),
          title: option.title ? option.title : option.label,
          seq: option.seq
        });
      }
    }
  });

  if (result.length > 0 && result[0].seq !== undefined) {
    return result.sort((a, b) => a.seq - b.seq);
  }

  return result;
}

function getTableWidth(config) {
  return Object.values(config.display).reduce((sum, val) => val.width + sum, 0);
}

function getColWidth(key, config) {
  return `${Math.round(
    (config.display[key].width / getTableWidth(config)) * 10000
  ) / 100}%`;
}
