import store from '../config/configureStore';
import Notifications from 'react-notification-system-redux';
import { setUserAuthenticated, setUserData, setUserUnit } from './UserReducer';

import { setEventInfowindow } from '../reducers/MapReducer';
import { getUnits } from '../reducers/UnitsReducer';

import { SET_EVENT_INFOWINDOW } from './MapReducer';

// Events
export const SET_EVENTS = 'EVENTS/SET_EVENTS';
export const SET_MY_EVENTS = 'EVENTS/SET_MY_EVENTS';
export const SET_EVENTS_INITIAL_LOAD = 'EVENTS/SET_MY_EVENTS';

const calculateDistance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist = dist * 1.609344;
    }
    if (unit === 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

export const updateEventInfoWindow = events => {
  const infoWindow = store.store.getState().map.eventInfowindow;
  return async dispatch => {
    if (infoWindow.open === true && infoWindow.data) {
      const ptsEventID = infoWindow.data.ptsEventID;
      const event = events.find(e => e.ptsEventID === ptsEventID);
      dispatch({
        type: SET_EVENT_INFOWINDOW,
        payload: { open: true, data: event }
      });
    }
  };
};

export const processEvents = events => {
  let filteredEvents = [];
  return async dispatch => {
    const userLocation = store.store.getState().user.location;
    const myEvents = store.store.getState().events.myEvents;
    const myUnit = store.store.getState().user.userUnit;
    const agencies = store.store.getState().settings.agencies;
    let _myEvents = [...myEvents];
    //Calculate unit distance to events
    events.forEach(event => {
      if (
        event.LatitudeDegree &&
        event.LongitudeDegree &&
        userLocation !== null
      ) {
        const lat1 =
          event.LatitudeSign === '-'
            ? event.LatitudeDegree * -1
            : event.LatitudeDegree;

        const lng1 =
          event.LongitudeSign === '-'
            ? event.LongitudeDegree * -1
            : event.LongitudeDegree;

        const lat2 = userLocation.latitude;
        const lng2 = userLocation.longitude;

        const distance = calculateDistance(lat1, lng1, lat2, lng2, 'M');
        event['distance'] = distance;
      }
      //End of calculate unit distance to events
      //Send a notification when new unit is dispached
      if (event.assignedUnits.length > 0) {
        for (let i = 0; i < event.assignedUnits.length; i++) {
          let unit = event.assignedUnits[i];
          if (myUnit && unit.ptsUnitID === myUnit.ptsUnitID) {
            event.myStatus = unit.Path;
            const isIn = myEvents.find(e => e.ptsEventID === event.ptsEventID);
            if (!isIn) {
              _myEvents.push(event);
              dispatch(setUserUnit());
              const notification = {
                //uid: 'once-please', // you can specify your own uid if required
                title: `New Event!`,
                message: `New Event has been dispatched: ${event.EventID}`,
                position: 'tr',
                autoDismiss: 0,
                action: {
                  label: 'Go to Event!',
                  callback: () => dispatch(setEventInfowindow(true, event))
                }
              };
              dispatch(Notifications.success(notification));
            }
            break;
          } else {
            /*
            _myEvents = _myEvents.filter(
              e => e.ptsEventID !== event.ptsEventID
            );
            */
          }
        }
      } else {
        _myEvents = _myEvents.filter(e => e.ptsEventID !== event.ptsEventID);
      }
      //End of send a notification when new unit is dispached
      //Filter data by AgencyId
      if (agencies !== null) {
        const caseIds = JSON.parse(event.CaseIds);
        if (caseIds !== null) {
          for (let i = 0; i < caseIds.length; i++) {
            if (agencies.includes(caseIds[i].AgencyId)) {
              filteredEvents.push(event);
              break;
            }
          }
        }
      } else {
        if (JSON.parse(event.CaseIds) !== null) {
          filteredEvents.push(event);
        }
      }
      //End of filter by AgencyId
    });
    dispatch({ type: SET_EVENTS, payload: filteredEvents });
    dispatch({ type: SET_MY_EVENTS, payload: _myEvents });
    dispatch(updateEventInfoWindow(filteredEvents));
  };
};

export const getEvents = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('events');
      service.timeout = 20000;
      const events = await service.find();
      dispatch(processEvents(events));
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      }
    }
  };
};

export const setEvents = events => {
  return async dispatch => {
    dispatch(processEvents(events));
  };
};

export const setEventNote = (event, note) => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('events');
      service.timeout = 20000;
      await service.patch(event.ptsEventID, {
        event: event,
        note: note
      });
      dispatch(getEvents());
    } catch (error) {
      console.log(error);
    }
  };
};

export const setEventDispositions = (event, dispositions) => {
  const client = store.store.getState().websocket.websocket;
  const myUnit = store.store.getState().user.userUnit;
  return async dispatch => {
    try {
      const service = client.service('events');
      service.timeout = 20000;
      await service.patch(event.ptsEventID, {
        event: event,
        unit: myUnit,
        dispositions: dispositions
      });
      dispatch(getEvents());
    } catch (error) {
      console.log(error);
    }
  };
};

export const setEventStatus = (event, status) => {
  const client = store.store.getState().websocket.websocket;
  const myUnit = store.store.getState().user.userUnit;
  const myEvents = store.store.getState().events.myEvents;
  let _myEvents = [...myEvents];
  return async dispatch => {
    try {
      const service = client.service('events');
      service.timeout = 20000;
      await service.patch(event.ptsEventID, {
        event: event,
        unit: myUnit,
        status: status,
        plate: null,
        plateState: null,
        oln: null,
        olnState: null,
        statusNotes: null,
        unitName: myUnit.Unit
      });
      _myEvents.push(event);
      dispatch({ type: SET_MY_EVENTS, payload: _myEvents });
      dispatch(getEvents());
      dispatch(getUnits());
      dispatch(setUserUnit());
    } catch (error) {
      console.log(error);
    }
  };
};

export const setEventAttachment = (eventId, file) => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    // console.log(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function() {
      const service = client.service('upload-attachment');
      service.timeout = 30000;
      await service.create({
        parentType: 'Event',
        ptsParentID: eventId,
        fileName: file.name,
        fileType: file.name.split('.')[1],
        data: reader.result.split(',')[1],
        description: ''
      });

      dispatch(getEvents());
    };

    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  };
};

export const removeEventAttachment = attachmentId => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    const service = client.service('upload-attachment');
    await service.remove(attachmentId);

    dispatch(getEvents());
  };
};

export default function reducer(
  state = {
    // Events
    events: null,
    myEvents: [],
    initialLoad: true
  },
  action
) {
  switch (action.type) {
    // Events
    case SET_EVENTS:
      return {
        ...state,
        events: action.payload
      };
    case SET_MY_EVENTS:
      return {
        ...state,
        myEvents: action.payload
      };
    case SET_EVENTS_INITIAL_LOAD:
      return {
        ...state,
        initialLoad: action.payload
      };
    default:
      break;
  }
  return state;
}
