export const SNACKBAR_ENQUEUE = 'SNACKBAR/SNACKBAR_ENQUEUE';
export const SNACKBAR_CLOSE = 'SNACKBAR/SNACKBAR_CLOSE';
export const SNACKBAR_REMOVE = 'SNACKBAR/SNACKBAR_REMOVE';

export const enqueueSnackbar = notification => {
  return dispatch => {
    const key = notification.options && notification.options.key;
    dispatch({
      type: SNACKBAR_ENQUEUE,
      notification: {
        ...notification,
        key: key || new Date().getTime() + Math.random()
      }
    });
  };
};

export const closeSnackbar = key => {
  return dispatch => {
    dispatch({
      type: SNACKBAR_CLOSE,
      dismissAll: !key, // dismiss all if no key has been defined
      key
    });
  };
};

export const removeSnackbar = key => {
  return dispatch => {
    dispatch({
      type: SNACKBAR_REMOVE,
      key
    });
  };
};

export default function reducer(
  state = {
    // Notifier
    notifications: []
  },
  action
) {
  switch (action.type) {
    // Notifier
    case SNACKBAR_ENQUEUE:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification
          }
        ]
      };
    case SNACKBAR_CLOSE:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      };
    case SNACKBAR_REMOVE:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key
        )
      };
    default:
      return state;
  }
}
