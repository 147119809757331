import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import { Card } from '@material-ui/core';

import SearchForm from './SearchForm';
import Table from './Table';

import PersonDetails from './SearchDetails/index';

import searchFiltersConfig from '../../config/search-filters-config.json';
import tableConfig from '../../config/table-config.json';

import {
  findPersons,
  sortPersons,
  findPerson
} from '../../reducers/PersonSearchReducer';

const SearchPerson = props => {
  const { persons, personDetails } = props.personSearch;
  const { themeMode } = props;
  const { loading, loaded, rows, error } = persons;

  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (personDetails.data !== null && personDetails.isShowing === true) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  }, [personDetails]);

  const getData = options => {
    props.findPersons(options);
  };

  const sort = name => {
    props.sortPersons(name);
  };

  const tableRowClick = row => {
    props.findPerson(row.ptsPersonID);
  };

  const closeDetails = () => {
    props.findPerson(null);
  };

  return (
    <Fragment>
      <SearchForm
        getData={getData}
        config={searchFiltersConfig}
        data={persons}
      />
      {rows.length > 0 && (
        <Table
          getData={getData}
          data={persons}
          config={tableConfig}
          sort={sort}
          rowClick={tableRowClick}
        />
      )}
      {loading && rows.length === 0 && <LoadingData />}
      {!loaded && !loading && rows.length === 0 && <StartSearching />}
      {loaded && !loading && rows.length === 0 && !error && <NoResults />}
      {loaded && !loading && rows.length === 0 && error && <ErrorLoading />}
      {showDetails && (
        <PersonDetails
          data={personDetails.data}
          showDetails={showDetails}
          close={closeDetails}
          themeMode={themeMode}
        />
      )}
    </Fragment>
  );
};

function NoResults() {
  return (
    <Card className="card-box bg-neutral-warning border-1 card-border-top border-warning text-center p-3">
      <h4 style={{ marginBottom: 0, color: 'black' }}>
        Sorry, no results match your search criteria.
      </h4>
    </Card>
  );
}

function LoadingData() {
  return (
    <p className="opacity-6 font-size-md mb-3">
      Loading the data, plase wait....
    </p>
  );
}

function StartSearching() {
  return (
    <Card className="card-box bg-neutral-success border-1 card-border-top border-success text-center p-3">
      <h4 style={{ marginBottom: 0, color: 'black' }}>
        Please define your search criteria.
      </h4>
    </Card>
  );
}

function ErrorLoading() {
  return (
    <Card className="card-box bg-neutral-danger border-1 card-border-top border-danger text-center p-3">
      <h4 style={{ marginBottom: 0, color: 'black' }}>
        There was an error while recieving the data.
      </h4>
    </Card>
  );
}

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  personSearch: state.personSearch
});

export default connect(mapStateToProps, {
  findPersons,
  sortPersons,
  findPerson
})(SearchPerson);
