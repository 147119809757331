import store from '../config/configureStore';

import { setUserAuthenticated, setUserData, setUserUnit } from './UserReducer';

// Units
export const SET_UNITS = 'UNITS/SET_UNITS';

export const processUnits = units => {
  const agencies = store.store.getState().settings.agencies;
  const myUnit = store.store.getState().user.userUnit;
  let filteredUnits = [];
  return async dispatch => {
    units.forEach((unit, idx) => {
      //Update my unit data
      if (unit.ptsUnitID === myUnit.ptsUnitID) {
        dispatch(setUserUnit(unit));
      }
      //End of update my unit data
      //Filter units by AgencyId
      if (agencies !== null) {
        if (agencies.includes(unit.AgencyID)) {
          filteredUnits.push(unit);
        }
      }
      //End off filter units by AgencyId
    });
    if (agencies == null) {
      filteredUnits = units;
    }
    dispatch({ type: SET_UNITS, payload: filteredUnits });
  };
};

export const getUnits = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('units');
      service.timeout = 20000;
      const units = await service.find();
      dispatch(processUnits(units));
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      }
    }
  };
};

export const setUnits = units => {
  return async dispatch => {
    dispatch(processUnits(units));
  };
};

export default function reducer(
  state = {
    // Units
    units: null
  },
  action
) {
  switch (action.type) {
    // Units
    case SET_UNITS:
      return {
        ...state,
        units: action.payload
      };
    default:
      break;
  }
  return state;
}
