import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Card } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh',
    padding: '10px 10px'
  },
  itemWrap: {
    fontWeight: 500
  },
  itemLabel: {
    fontWeight: 200
  }
}));

function Citation(props) {
  const { data, themeMode } = props;
  const classes = useStyles();

  if (data !== null) {
    return (
      <div className="table-responsive">
        <table
          className={clsx(
            'table',
            themeMode === 'night' && 'table-pts-night',
            themeMode === 'day' && 'table-pts-day',
            'text-nowrap',
            'mb-0'
          )}>
          <thead>
            <tr>
              <th className="bg-white">Date</th>
              <th className="bg-white text-center">Number</th>
              <th className="bg-white text-center">Officer</th>
              <th className="bg-white text-center">Description</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    {row.CitationDate
                      ? moment(row.CitationDate).format('MM/DD/YYYY, h:mm a')
                      : ''}
                  </td>
                  <td>{row.CitationNumber}</td>
                  <td>{row.OfficerNameFull}</td>
                  <td>{row.StatueDescription}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <Card className="card-box bg-neutral-warning border-1 card-border-top border-warning text-center p-3">
        <h4 style={{ marginBottom: 0, color: 'black' }}>
          Sorry, no citations found.
        </h4>
      </Card>
    );
  }
}

export default Citation;
