import React, { Fragment } from 'react';
import { ClimbingBoxLoader } from 'react-spinners';

import { Box } from '@material-ui/core';

const Loader = props => {
  const { text } = props;
  return (
    <Fragment>
      <div className="d-flex align-items-center flex-column vh-60 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader color={'#ac0a26'} loading={true} />
          <Box component="span" color={'#ac0a26'} m={1}>
            {text && <div>{text}</div>}
          </Box>
        </div>
      </div>
    </Fragment>
  );
};

export default Loader;
