import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh',
    padding: '10px 10px'
  },
  itemWrap: {
    fontWeight: 500,
    fontSize: '0.95rem'
  },
  itemLabel: {
    fontWeight: 200
  }
}));

function General(props) {
  const { data } = props;
  console.log(data);
  const classes = useStyles();

  return (
    <div style={{ padding: '10px' }}>
      <h4>{data.FullName}</h4>
      <div className="divider my-1" />
      <div className={classes.itemWrap}>
        <span className={clsx('text-muted', classes.itemLabel)}>
          Last Name:{' '}
        </span>
        {data.LastName}
      </div>
      <div className={classes.itemWrap}>
        <span className={clsx('text-muted', classes.itemLabel)}>
          First Name:{' '}
        </span>
        {data.FirstName}
      </div>
      <div className={classes.itemWrap}>
        <span className={clsx('text-muted', classes.itemLabel)}>
          Middle Name:{' '}
        </span>
        {data.MiddleName}
      </div>
      <div className="divider my-1" />
      <div className={classes.itemWrap}>
        <span className={clsx('text-muted', classes.itemLabel)}>Sex: </span>
        {data.Sex}
      </div>
      <div className={classes.itemWrap}>
        <span className={clsx('text-muted', classes.itemLabel)}>Race: </span>
        {data.Race}
      </div>
      <div className={classes.itemWrap}>
        <span className={clsx('text-muted', classes.itemLabel)}>DOB: </span>
        {data.DOB ? moment(data.DOB).format('MM/DD/YYYY') : ''}
      </div>
      <div className={classes.itemWrap}>
        <span className={clsx('text-muted', classes.itemLabel)}>Height: </span>
        {data.Height}
      </div>
      <div className={classes.itemWrap}>
        <span className={clsx('text-muted', classes.itemLabel)}>Weight: </span>
        {data.Weight}
      </div>
      <div className={classes.itemWrap}>
        <span className={clsx('text-muted', classes.itemLabel)}>
          Hair Color:{' '}
        </span>
        {data.HairColor}
      </div>
      <div className={classes.itemWrap}>
        <span className={clsx('text-muted', classes.itemLabel)}>
          Eye Color:{' '}
        </span>
        {data.EyeColor}
      </div>
      <div className={classes.itemWrap}>
        <span className={clsx('text-muted', classes.itemLabel)}>OLN: </span>
        {data.OLNNumber}
      </div>
      <div className={classes.itemWrap}>
        <span className={clsx('text-muted', classes.itemLabel)}>SSN: </span>
        {data.SSN}
      </div>
    </div>
  );
}

export default General;
