/* UnitActionsCodes
CAD.DefaultCodes.UnitActions.Arrived	ARRIVED
CAD.DefaultCodes.UnitActions.Cancelled	CANCELLED
CAD.DefaultCodes.UnitActions.Completed	COMPLETED
CAD.DefaultCodes.UnitActions.Dispatch	DISPATCH
CAD.DefaultCodes.UnitActions.Enroute	ENROUTE
CAD.DefaultCodes.UnitActions.Inservice	INSERVICE
CAD.DefaultCodes.UnitActions.Outservice	OFFDUTY
CAD.DefaultCodes.UnitActions.Queued	QUEUED
*/

export const getEventColor = status => {
  let bgColor = '#a0b0c3';
  switch (status) {
    case 'Pending':
      return (bgColor = '#e79468');
    case 'Dispatched':
      return (bgColor = '#ecb743');
    case 'OnHold':
      return (bgColor = '#ecb743');
    case 'Enroute':
      return (bgColor = '#c7c11e');
    case 'Arrived':
      return (bgColor = '#ed474b');
    case 'Completed':
      return (bgColor = '#a0b0c3');
    default:
      return (bgColor = '#a0b0c3');
  }
};

export const getUnitColor = status => {
  let bgColor = '#a0b0c3';
  switch (status) {
    case 'CAD.DefaultCodes.UnitActions.Inservice':
      return (bgColor = '#e79468');
    case 'CAD.DefaultCodes.UnitActions.Dispatch':
      return (bgColor = '#ecb743');
    case 'CAD.DefaultCodes.UnitActions.Queued':
      return (bgColor = '#EC2418');
    case 'CAD.DefaultCodes.UnitActions.Outservice':
      return (bgColor = '#ff0000');
    case 'CAD.DefaultCodes.UnitActions.Enroute':
      return (bgColor = '#c7c11e');
    case 'CAD.DefaultCodes.UnitActions.Arrived':
      return (bgColor = '#ed474b');
    case 'CAD.DefaultCodes.UnitActions.Completed':
      return (bgColor = '#a0b0c3');
    default:
      return (bgColor = '#a0b0c3');
  }
};
