// Websocket
export const SET_WEBSOCKET_CONNECTION = 'WEBSOCKET/SET_WEBSOCKET_CONNECTION';
export const SET_WEBSOCKET_AUTHENTICATED =
  'WEBSOCKET/SET_WEBSOCKET_AUTHENTICATED';

export const setWebsocketConnection = client => {
  return dispatch => {
    dispatch({ type: SET_WEBSOCKET_CONNECTION, payload: client });
  };
};

export default function reducer(
  state = { websocket: null, isAuthenticated: false },
  action
) {
  switch (action.type) {
    case SET_WEBSOCKET_CONNECTION:
      return {
        ...state,
        websocket: action.payload
      };
    default:
      break;
  }
  return state;
}
