import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Hidden,
  Drawer,
  IconButton,
  Box,
  Tooltip,
  ListItem,
  List,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';

import { TiHome } from 'react-icons/ti';
import { FaSearch } from 'react-icons/fa';

import { makeStyles } from '@material-ui/core/styles';

import { setNavigationDrawerMobileState } from '../../reducers/UiReducer';
import projectLogo from '../../assets/images/logo.png';

const drawerWidth = 240;
const drawerHeight = 80;

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.colorFont + '!important'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: 99
    }
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.bgElements
  },
  toolbar: {
    height: drawerHeight,
    backgroundColor: theme.palette.bgElements
  },
  logoText: {
    color: theme.palette.colorFont
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0
  }
}));

const DrawerNavigation = props => {
  const { state, setNavigationDrawerMobileState } = props;
  const classes = useStyles();

  return (
    <Hidden mdUp>
      <Drawer
        variant="temporary"
        anchor="left"
        open={state}
        onClose={() => setNavigationDrawerMobileState(false)}
        classes={{
          paper: classes.drawerPaper
        }}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}>
        <Fragment>
          <div
            className="app-sidebar-header"
            style={{
              width: '240px',
              height: '80px',
              background: 'transparent',
              borderBottom: '1px solid #fff'
            }}>
            <Box className="header-logo-wrapper" title="CAD Mobile">
              <Link to="/Main" className="header-logo-wrapper-link">
                <IconButton
                  color="primary"
                  size="medium"
                  style={{ background: 'transparent' }}
                  className="header-logo-wrapper-btn">
                  <img className="app-sidebar-logo" alt="" src={projectLogo} />
                </IconButton>
              </Link>
              <Box
                className={classes.logoText}
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.1rem',
                  color: '#ac0a26'
                }}>
                CAD Mobile
              </Box>
            </Box>
            <Box className="app-sidebar-header-btn">
              <Tooltip title="Toggle Navigation" placement="right">
                <IconButton
                  style={{ color: '#ac0a26' }}
                  onClick={() => setNavigationDrawerMobileState()}
                  size="medium">
                  <MenuOpenRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className="app-sidebar-header-btn-mobile">
              <Tooltip title="Toggle Navigation" placement="right">
                <IconButton
                  style={{ color: '#ac0a26' }}
                  onClick={() => setNavigationDrawerMobileState()}
                  size="medium">
                  <MenuOpenRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </div>
          <div>
            <List>
              <Link
                onClick={() => setNavigationDrawerMobileState()}
                className={classes.link}
                to="/Main">
                <ListItem button key="home">
                  <ListItemIcon>
                    <TiHome style={{ fontSize: '2.5rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
              </Link>
              <Link
                onClick={() => setNavigationDrawerMobileState()}
                className={classes.link}
                to="/Search">
                <ListItem button key="search">
                  <ListItemIcon>
                    <FaSearch style={{ fontSize: '1.9rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Search" />
                </ListItem>
              </Link>
            </List>
          </div>
        </Fragment>
      </Drawer>
    </Hidden>
  );
};

const mapStateToProps = state => ({
  state: state.ui.navigationDrawerMobileState
});

export default connect(mapStateToProps, {
  setNavigationDrawerMobileState
})(DrawerNavigation);
