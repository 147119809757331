import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';

import avatarPlaceholder from '../../../../assets/images/avatars/avatar-526x526.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh',
    padding: '10px 10px'
  },
  itemWrap: {
    fontWeight: 500,
    fontSize: '0.95rem'
  },
  itemLabel: {
    fontWeight: 200
  },
  imageItem: {
    float: 'left',
    width: '50%',
    padding: '10px'
  }
}));

function RenderImage(mugshot) {
  const classes = useStyles();
  if (mugshot !== null) {
    //const base64 = arrayBufferToBase64(mugshot);
    const base64 = mugshot;
    return (
      <div className={classes.imageItem}>
        <img
          alt="mugshot"
          className="img-fluid border-3 border-white"
          src={`data:image/png;base64,${base64}`}
        />
      </div>
    );
  } else {
    return (
      <div className={classes.imageItem}>
        <img
          className="img-fluid border-3 border-white"
          src={avatarPlaceholder}
          alt="mugshot"
        />
      </div>
    );
  }
}

function Photos(props) {
  const { data } = props;
  console.log(data);
  const classes = useStyles();
  if (data !== null && data.length > 0) {
    return (
      <div>
        {data.map((row, idx) => {
          return RenderImage(row.FileObject);
        })}
      </div>
    );
  } else {
    return (
      <Card className="card-box bg-neutral-warning border-1 card-border-top border-warning text-center p-3">
        <h4 style={{ marginBottom: 0, color: 'black' }}>
          Sorry, no photos found.
        </h4>
      </Card>
    );
  }
}

export default Photos;

// Helper
function arrayBufferToBase64(buffer) {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
