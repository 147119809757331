import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as notifications } from 'react-notification-system-redux';

import AppInfoReducer from './AppInfoReducer';
import ThemeReducer from './ThemeReducer';
import WebsocketReducer from './WebsocketReducer';
import NotifierReducer from './NotifierReducer';
import UiReducer from './UiReducer';
import SettingsReducer from './SettingsReducer';
import UserReducer from './UserReducer';
import MapReducer from './MapReducer';
import UnitsReducer from './UnitsReducer';
import EventsReducer from './EventsReducer';
import DataReducer from './DataReducer';
import PersonSearchReducer from './PersonSearchReducer';
import CodeReducer from './CodeReducer';
import UnitStatusReducer from './UnitStatusReducer';

const createRootReducer = history =>
  combineReducers({
    appInfo: AppInfoReducer,
    router: connectRouter(history),
    theme: ThemeReducer,
    websocket: WebsocketReducer,
    notifier: NotifierReducer,
    notifications: notifications,
    ui: UiReducer,
    settings: SettingsReducer,
    user: UserReducer,
    map: MapReducer,
    units: UnitsReducer,
    events: EventsReducer,
    data: DataReducer,
    personSearch: PersonSearchReducer,
    codes: CodeReducer,
    unitStatus: UnitStatusReducer
  });

export default createRootReducer;
