import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Tabs,
  Tab,
  Box,
  Badge
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';

import General from './General';
import Photos from './Photos';
import Contact from './Contact';
import Address from './Address';
import Warrant from './Warrant';
import Citation from './Citation';
import Booking from './Booking';
import CadIncident from './CadIncident';
import RmsIncident from './RmsIncident';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh',
    padding: '10px 10px'
  },
  itemWrap: {
    fontWeight: 500
  },
  itemLabel: {
    fontWeight: 200
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function PersonDetails(props) {
  const theme = useTheme();
  const classes = useStyles();
  const { data, themeMode } = props;

  const [tabs, setTabs] = useState([]);
  const [tab, setTab] = useState(0);

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    props.close();
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  React.useEffect(() => {
    const setTabsConfig = () => {
      const _tabs = [];
      let tabKey = 0;
      Object.entries(data).map(([key, val], idx) => {
        switch (key) {
          case 'General':
            _tabs.push({
              label: 'General',
              data: JSON.parse(val)[0],
              key: tabKey,
              component: 'General'
            });
            tabKey++;
            break;
          case 'Mugshots':
            if (val !== null) {
              const parsedData = JSON.parse(val);
              if (val.length > 0) {
                _tabs.push({
                  label: 'Photos',
                  data: parsedData,
                  count: parsedData.length,
                  key: tabKey,
                  component: 'Photos'
                });
                tabKey++;
              }
            }
            break;
          case 'ContactInfo':
            if (val !== null) {
              const parsedData = JSON.parse(val);
              if (val.length > 0) {
                _tabs.push({
                  label: 'Contacts',
                  data: parsedData,
                  count: parsedData.length,
                  key: tabKey,
                  component: 'Contact'
                });
                tabKey++;
              }
            }
            break;
          case 'Addresses':
            if (val !== null) {
              const parsedData = JSON.parse(val);
              if (val.length > 0) {
                _tabs.push({
                  label: 'Addresses',
                  data: parsedData,
                  count: parsedData.length,
                  key: tabKey,
                  component: 'Address'
                });
                tabKey++;
              }
            }
            break;
          case 'Warrants':
            if (val !== null) {
              const parsedData = JSON.parse(val);
              if (val.length > 0) {
                _tabs.push({
                  label: 'Warrants',
                  data: parsedData,
                  count: parsedData.length,
                  key: tabKey,
                  component: 'Warrant'
                });
                tabKey++;
              }
            }
            break;
          case 'Citations':
            if (val !== null) {
              const parsedData = JSON.parse(val);
              if (val.length > 0) {
                _tabs.push({
                  label: 'Citations',
                  data: parsedData,
                  count: parsedData.length,
                  key: tabKey,
                  component: 'Citation'
                });
                tabKey++;
              }
            }
            break;
          case 'Bookings':
            if (val !== null) {
              const parsedData = JSON.parse(val);
              if (val.length > 0) {
                _tabs.push({
                  label: 'Bookings',
                  data: parsedData,
                  count: parsedData.length,
                  key: tabKey,
                  component: 'Booking'
                });
                tabKey++;
              }
            }
            break;
          case 'CadIncidents':
            if (val !== null) {
              const parsedData = JSON.parse(val);
              if (val.length > 0) {
                _tabs.push({
                  label: 'CAD Incidents',
                  data: parsedData,
                  count: parsedData.length,
                  key: tabKey,
                  component: 'CadIncident'
                });
                tabKey++;
              }
            }
            break;
          case 'RmsIncidents':
            if (val !== null) {
              const parsedData = JSON.parse(val);
              if (val.length > 0) {
                _tabs.push({
                  label: 'CAD Incidents',
                  data: parsedData,
                  count: parsedData.length,
                  key: tabKey,
                  component: 'RmsIncident'
                });
                tabKey++;
              }
            }
            break;
          default:
        }
      });
      setTabs(_tabs);
    };
    setTabsConfig();
  }, [data]);

  const generateTab = (data, idx) => {
    if (data.label === 'General') {
      return <Tab label={data.label} />;
    } else {
      return (
        <Tab
          label={
            <Badge badgeContent={data.count} color="primary">
              {data.label}
            </Badge>
          }
        />
      );
    }
  };

  const generateTabs = () => {
    return (
      <Tabs
        value={tab}
        variant="scrollable"
        scrollButtons="on"
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="person details tabs">
        {tabs.map((row, idx) => {
          return generateTab(row, idx);
        })}
      </Tabs>
    );
  };

  const generateTabContent = (data, idx) => {
    if (data.component === 'General') {
      return (
        <TabPanel key={idx} value={tab} index={data.key}>
          <General data={data.data} themeMode={themeMode} />
        </TabPanel>
      );
    } else if (data.component === 'Photos') {
      return (
        <TabPanel key={idx} value={tab} index={data.key}>
          <Photos data={data.data} themeMode={themeMode} />
        </TabPanel>
      );
    } else if (data.component === 'Contact') {
      return (
        <TabPanel key={idx} value={tab} index={data.key}>
          <Contact data={data.data} themeMode={themeMode} />
        </TabPanel>
      );
    } else if (data.component === 'Address') {
      return (
        <TabPanel key={idx} value={tab} index={data.key}>
          <Address data={data.data} themeMode={themeMode} />
        </TabPanel>
      );
    } else if (data.component === 'Warrant') {
      return (
        <TabPanel key={idx} value={tab} index={data.key}>
          <Warrant data={data.data} themeMode={themeMode} />
        </TabPanel>
      );
    } else if (data.component === 'Citation') {
      return (
        <TabPanel key={idx} value={tab} index={data.key}>
          <Citation data={data.data} themeMode={themeMode} />
        </TabPanel>
      );
    } else if (data.component === 'Booking') {
      return (
        <TabPanel key={idx} value={tab} index={data.key}>
          <Booking data={data.data} themeMode={themeMode} />
        </TabPanel>
      );
    } else if (data.component === 'CadIncident') {
      return (
        <TabPanel key={idx} value={tab} index={data.key}>
          <CadIncident data={data.data} themeMode={themeMode} />
        </TabPanel>
      );
    } else if (data.component === 'RmsIncident') {
      return (
        <TabPanel key={idx} value={tab} index={data.key}>
          <RmsIncident data={data.data} themeMode={themeMode} />
        </TabPanel>
      );
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={true}
      onClose={handleClose}
      scroll="paper"
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth={'md'}>
      <DialogTitle
        style={{ padding: '10px 0px 0px 0px' }}
        id="scroll-dialog-title">
        {generateTabs()}
      </DialogTitle>
      <DialogContent
        dividers={true}
        className={classes.body}
        id="person-dialog">
        {tabs.map((row, idx) => {
          return generateTabContent(row, idx);
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PersonDetails;
