import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useLoadScript } from '@react-google-maps/api';

import RenderMap from '../../components/Map';
import Loader from '../../global-components/Loader';

import { getUnits, setUnits } from '../../reducers/UnitsReducer';
import { getEvents, setEvents } from '../../reducers/EventsReducer';
import { getDispositions, getAgencies } from '../../reducers/DataReducer';

const PagesMain = props => {
  const {
    getUnits,
    setUnits,
    getEvents,
    setEvents,
    getDispositions,
    getAgencies,
    units,
    events
  } = props;

  const { wsClient } = props;

  React.useEffect(() => {
    if (wsClient.websocket) {
      getEvents();
      getUnits();
      getDispositions();
      getAgencies();
    }
  }, [getEvents, getUnits, getDispositions, getAgencies, wsClient]);

  React.useEffect(() => {
    if (wsClient.websocket) {
      const onEventsListener = events => setEvents(events);
      wsClient.websocket
        .service('events')
        .on('newEventsData', onEventsListener);
      const onUnitsListener = units => setUnits(units);
      wsClient.websocket.service('units').on('newUnitsData', onUnitsListener);
      return () => {
        wsClient.websocket
          .service('events')
          .removeListener('newEventsData', onEventsListener);
        wsClient.websocket
          .service('units')
          .removeListener('newUnitsData', onUnitsListener);
      };
    }
  }, [setEvents, setUnits, wsClient]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY
  });

  if (loadError) {
    return (
      <Fragment>
        <Loader />
      </Fragment>
    );
  }

  return isLoaded && units && events ? <RenderMap /> : <Loader />;
};

const mapStateToProps = state => ({
  wsClient: state.websocket,
  themeMode: state.theme.mode,
  units: state.units.units,
  events: state.events.events
});

export default connect(mapStateToProps, {
  getUnits,
  setUnits,
  getEvents,
  setEvents,
  getDispositions,
  getAgencies
})(PagesMain);
