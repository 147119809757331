import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { createOffline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults/index';

import createRootReducer from '../reducers';

export const history = createBrowserHistory();

const persistConfig = {
  key: `root-${process.env.REACT_APP_VERSION}`,
  whitelist: [
    'offline',
    'theme',
    'user',
    'units',
    'events',
    'settings',
    'codes'
  ],
  storage
};

const {
  middleware: offlineMiddleware,
  enhanceReducer: offlineEnhanceReducer,
  enhanceStore: offlineEnhanceStore
} = createOffline({
  ...offlineConfig,
  persist: false,
  persistAutoRehydrate: false
});

const persistedReducer = persistReducer(
  persistConfig,
  offlineEnhanceReducer(createRootReducer(history))
);

let store = createStore(
  persistedReducer,
  compose(
    offlineEnhanceStore,
    applyMiddleware(ReduxThunk, offlineMiddleware, routerMiddleware(history))
  )
);
let persistor = persistStore(store);

export default { store, persistor };
