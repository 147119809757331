import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { Hidden, Drawer, List, ListItem, IconButton } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';

import { makeStyles } from '@material-ui/core/styles';

import { IoIosCar } from 'react-icons/io';

import { setUnitsDrawerMobileState } from '../../reducers/UiReducer';
import { setUnitInfowindow } from '../../reducers/MapReducer';

import { getUnitColor } from '../../utils/uiUtils';

const drawerWidth = 240;
const drawerHeight = 80;

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    },
    zIndex: 98
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.bgElements,
    padding: '0px 5px'
  },
  toolbar: {
    height: drawerHeight,
    backgroundColor: theme.palette.bgElements
  },
  topHeaderBar: {
    position: 'fixed',
    backgroundColor: theme.palette.bgElements,
    zIndex: 99,
    width: 230
  }
}));

const DrawerUnitsMobile = props => {
  const {
    state,
    setUnitsDrawerMobileState,
    units,
    themeMode,
    setUnitInfowindow,
    unitInfowindow
  } = props;

  const classes = useStyles();

  const onUnitClick = unit => {
    setUnitsDrawerMobileState(false);
    setUnitInfowindow(true, unit);
  };

  const renderUnit = unit => {
    if (themeMode === 'day') {
      let border = 'none';
      if (
        unitInfowindow.open === true &&
        unitInfowindow.data.ptsUnitID === unit.ptsUnitID
      ) {
        border = '2px solid rgba(0, 0, 0, 0.87)';
      }
      return (
        <ListItem
          button
          style={{
            border: border,
            color: '#fff',
            fontSize: '0.9rem',
            backgroundColor: getUnitColor(unit.Path),
            marginBottom: '5px',
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '5px',
            paddingRight: '5px'
          }}
          key={unit.ptsUnitID}
          onClick={() => onUnitClick(unit)}>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between'
              }}>
              <div>
                <span style={{ fontWeight: 'bold' }}>
                  {' '}
                  <IoIosCar size={18} color="#FFF" /> {unit.Unit}
                </span>
              </div>
              <div>
                <span>{unit.UnitStatus}</span>
              </div>
            </div>
            <div>
              <span
                style={{
                  fontStyle: 'italic',
                  fontSize: '0.8rem',
                  paddingRight: '3px'
                }}>
                {moment(unit.LastActionOccurred).format('MM/DD/YYYY, h:mm a')}
              </span>
            </div>
            <div className="divider my-1" />
            <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Agency</div>
            <div>
              <span style={{ fontSize: '0.8rem' }}>{unit.AgencyID}</span>
            </div>
            <div className="divider my-1" />
            <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
              Division
            </div>
            <div>
              <span style={{ fontSize: '0.8rem' }}>{unit.Division}</span>
            </div>
            <div className="divider my-1" />
            <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
              Resources
            </div>
            <div>
              <span style={{ fontSize: '0.8rem' }}>{unit.UnitResources}</span>
            </div>
          </div>
        </ListItem>
      );
    } else {
      let background = 'transparent';
      let borderColor = getUnitColor(unit.Path);
      if (
        unitInfowindow.open === true &&
        unitInfowindow.data.ptsUnitID === unit.ptsUnitID
      ) {
        background = getUnitColor(unit.Path);
        borderColor = '#fff';
      }
      return (
        <ListItem
          button
          style={{
            background: background,
            color: '#fff',
            fontSize: '0.9rem',
            border: `1px solid ${getUnitColor(unit.Path)}`,
            marginBottom: '5px',
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '5px',
            paddingRight: '5px'
          }}
          key={unit.ptsUnitID}
          onClick={() => onUnitClick(unit)}>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between'
              }}>
              <div>
                <span style={{ fontWeight: 'bold' }}>
                  {' '}
                  <IoIosCar size={18} color={getUnitColor(unit.Path)} />{' '}
                  {unit.Unit}
                </span>
              </div>
              <div>
                <span style={{ color: getUnitColor(unit.Path) }}>
                  {unit.UnitStatus}
                </span>
              </div>
            </div>
            <div>
              <span
                style={{
                  fontStyle: 'italic',
                  fontSize: '0.8rem',
                  paddingRight: '3px'
                }}>
                {moment(unit.LastActionOccurred).format('MM/DD/YYYY, h:mm a')}
              </span>
            </div>
            <div style={{ background: borderColor }} className="divider my-1" />
            <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Agency</div>
            <div>
              <span style={{ fontSize: '0.8rem' }}>{unit.AgencyID}</span>
            </div>
            <div style={{ background: borderColor }} className="divider my-1" />
            <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Divison</div>
            <div>
              <span style={{ fontSize: '0.8rem' }}>{unit.Division}</span>
            </div>
            <div style={{ background: borderColor }} className="divider my-1" />
            <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
              Resources
            </div>
            <div>
              <span style={{ fontSize: '0.8rem' }}>{unit.UnitResources}</span>
            </div>
          </div>
        </ListItem>
      );
    }
  };

  const renderUnits = () => {
    if (units && units.length > 0) {
      return (
        <List style={{ paddingTop: '50px' }}>
          {units.map((unit, index) => renderUnit(unit))}
        </List>
      );
    } else {
      return (
        <Fragment>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </Fragment>
      );
    }
  };

  const drawer = (
    <div>
      <div className={classes.topHeaderBar}>
        <div className="divider my-1" style={{ background: '#ac0a26' }} />
        <div
          className="text-center"
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              alignSelf: 'center',
              color: '#ac0a26',
              fontSize: '1.1rem'
            }}
            className="font-weight-bold font-size-md mb-0">
            Units
          </div>
          <IconButton
            style={{ color: '#ac0a26' }}
            onClick={() => setUnitsDrawerMobileState()}
            size="small"
            className="btn-inverse">
            <MenuOpenRoundedIcon />
          </IconButton>
        </div>
        <div className="divider my-1" style={{ background: '#ac0a26' }} />
      </div>
      {renderUnits()}
    </div>
  );

  return (
    <Fragment>
      <Hidden mdUp>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          open={state}
          anchor="left"
          classes={{
            paper: classes.drawerPaper
          }}>
          <div>
            <div className={classes.toolbar} />
            {drawer}
            {/*<div className={classes.toolbar} />*/}
          </div>
        </Drawer>
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  state: state.ui.unitsDrawerMobileState,
  units: state.units.units,
  themeMode: state.theme.mode,
  unitInfowindow: state.map.unitInfowindow
});

export default connect(mapStateToProps, {
  setUnitsDrawerMobileState,
  setUnitInfowindow
})(DrawerUnitsMobile);
