import React, { useState, useEffect, useRef, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import moment from 'moment';

import { TextField, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { getCodeSex, getCodeRace } from '../../../reducers/CodeReducer';

const useStyles = makeStyles(theme => ({
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginBottom: '1em'
  },
  filters: {
    padding: 20,
    marginBottom: 20
  },
  filterAction: {
    textAlign: 'right',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  filter: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  datePicker: {
    marginTop: 10,
    width: 200,
    margin: '16px 10px 10px',
    paddingTop: 6,
    '& input::-webkit-input-placeholder': {
      fontSize: 14,
      opacity: 0.6
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  select: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  selectInput: {
    fontSize: '16px'
  },
  iconButton: {
    padding: 10
  },
  reset: {
    marginRight: 20
  },
  searchBtnWrap: {
    display: 'inline-block',
    position: 'relative',
    paddingRight: '1em'
  },
  buttonProgress: {
    color: '#B00927',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const SearchForm = props => {
  const classes = useStyles();
  const [filters, setFilters] = useState(props.config.display);
  const [filterValues, setFilterValues] = useState({});
  const { loaded } = props.data;
  const [loading, setLoading] = React.useState(false);
  const filterRefs = {};
  const [pickerValues, setPickerValues] = useState({});
  const { getCodeSex, getCodeRace, wsClient } = props;
  const codes = {
    sex: props.sexCodes,
    race: props.raceCodes
  };

  Object.values(filters).forEach(({ dbName }) => {
    // eslint-disable-next-line
    filterRefs[dbName] = useRef();
  });

  useEffect(() => {
    loaded && loading && setLoading(false);
  }, [loaded, loading]);

  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeSex();
      getCodeRace();
    }
  }, [getCodeSex, getCodeRace, wsClient]);

  const submitForm = e => {
    e.preventDefault();
    const { orderBy, rowsPerPage, orderDirection } = props.data;
    const newFilterValues = { ...filterValues };
    Object.entries(pickerValues).forEach(([key, val]) => {
      newFilterValues[key] = {
        type: 'date',
        from: moment(val.from).format('YYYY-MM-DD') + ' 00:00:00.0',
        to: moment(val.to).format('YYYY-MM-DD') + ' 23:59:59.0'
      };
    });
    const options = {
      pageNo: 0,
      orderBy,
      rowsPerPage,
      orderDirection,
      filters: newFilterValues
    };
    props.getData(options);
    setLoading(true);
  };

  const handleFilterChange = filter => ev => {
    const values = { ...filterValues };
    values[filter.dbName] = { type: filter.type, search: ev.target.value };
    setFilterValues(cleanFilters(values));
  };

  const getTextFilter = (filter, idx) => (
    <TextField
      type="search"
      variant="outlined"
      label={filter.label}
      key={idx}
      className={classes.filter}
      onChange={handleFilterChange(filter)}
      inputRef={filterRefs[filter.dbName]}
    />
  );

  const handleDateChange = (date, dbName, param) => {
    const value = pickerValues[dbName]
      ? pickerValues[dbName]
      : { from: null, to: null };
    value[param] = date;
    if (param === 'from' && !value.to) value.to = date;
    if (param === 'to' && !value.from) value.from = date;
    if (param === 'single') {
      value.from = date;
      value.to = date;
    }
    const currentPickerValue = { ...pickerValues };
    if (value.from !== null && value.to !== null) {
      currentPickerValue[dbName] = value;
    } else {
      delete currentPickerValue[dbName];
    }

    setPickerValues(currentPickerValue);
  };

  const getDateFilter = (filter, idx) => {
    const value = pickerValues[filter.dbName]
      ? pickerValues[filter.dbName]
      : { from: null, to: null };
    const minToValue = value.from ? value.from : undefined;
    const maxFromValue = value.to ? value.to : new Date();
    return (
      <Fragment key={idx}>
        <KeyboardDatePicker
          className={classes.datePicker}
          clearable
          value={value.from}
          placeholder={`${filter.label} From`}
          onChange={date => handleDateChange(date, filter.dbName, 'from')}
          maxDate={maxFromValue}
          format="MM/DD/yyyy"
        />
        <KeyboardDatePicker
          className={classes.datePicker}
          clearable
          value={value.to}
          placeholder={`${filter.label} To`}
          onChange={date => handleDateChange(date, filter.dbName, 'to')}
          minDate={minToValue}
          maxDate={new Date()}
          format="MM/DD/yyyy"
        />
      </Fragment>
    );
  };

  const getDateSingleFilter = (filter, idx) => {
    const value = pickerValues[filter.dbName]
      ? pickerValues[filter.dbName]
      : { from: null, to: null };
    return (
      <KeyboardDatePicker
        style={{ marginTop: 0, paddingTop: 0 }}
        key={idx}
        variant="inline"
        inputVariant="outlined"
        className={classes.datePicker}
        clearable
        value={value.from}
        placeholder={filter.label}
        onChange={date => handleDateChange(date, filter.dbName, 'single')}
        format="MM/DD/yyyy"
      />
    );
  };

  const getSelectFilter = (filter, idx) => (
    <FormControl variant="outlined" className={classes.select} key={idx}>
      <InputLabel id={`filter-${filter.dbName}`}>{filter.label}</InputLabel>
      <Select
        className={classes.selectInput}
        labelId={`filter-${filter.dbName}`}
        value={
          filterValues[filter.dbName] ? filterValues[filter.dbName].search : ''
        }
        onChange={handleFilterChange(filter)}>
        <MenuItem value=""> </MenuItem>

        {codes[filter.label.toLowerCase()].map((code, idx) => (
          <MenuItem value={code.Code} key={idx}>
            {code.Code}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const clearForm = () => {
    Object.values(filterRefs).forEach(({ current }) => {
      if (current) current.value = '';
    });
    setFilterValues({});
    setPickerValues({});
  };

  return (
    <div className={classes.searchWrap}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <form onSubmit={submitForm}>
          <div>
            {Object.values(filters).map((filter, idx) => {
              switch (filter.type) {
                case 'date':
                  return getDateFilter(filter, idx);
                case 'date-single':
                  return getDateSingleFilter(filter, idx);
                case 'select':
                  return getSelectFilter(filter, idx);
                default:
                  return getTextFilter(filter, idx);
              }
            })}
          </div>
          <div className={classes.filterAction}>
            <Button
              size="large"
              startIcon={<DeleteOutlineIcon />}
              onClick={clearForm}
              className={classes.reset}
              disabled={loading}>
              Clear
            </Button>
            <div className={classes.searchBtnWrap}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<SearchIcon />}
                type="submit"
                disabled={loading}>
                Search
              </Button>
              {loading && (
                <CircularProgress
                  color="primary"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
        </form>
      </MuiPickersUtilsProvider>
    </div>
  );
};

const mapStateToProps = state => ({
  wsClient: state.websocket,
  themeMode: state.theme.mode,
  sexCodes: state.codes.sexCodes,
  raceCodes: state.codes.raceCodes
});

export default connect(mapStateToProps, {
  getCodeSex,
  getCodeRace
})(SearchForm);

function cleanFilters(obj) {
  const result = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (val.search !== '') result[key] = val;
  });
  return result;
}
