import { enqueueSnackbar } from '../reducers/NotifierReducer';
import store from '../config/configureStore';
import tableConfig from '../config/table-config.json';

import { showSpinner, hideSpinner } from './UiReducer';

// Person Search
export const FIND_PERSONS = 'PERSONSEARCH/FIND';
export const LOADING_PERSONS = 'PERSONSEARCH/LOADING';
export const LOADING_PERSONS_ERROR = 'PERSONSEARCH/LOADING_ERROR';

export const FIND_PERSON = 'PERSONSEARCH/FIND_PERSON';
export const OPEN_DETAILS = 'PERSONSEARCH/OPEN_DETAILS';
export const CLOSE_DETAILS = 'PERSONSEARCH/CLOSE_DETAILS';

export const findPersons = params => {
  return async dispatch => {
    const state = store.store.getState();
    const client = state.websocket.websocket;
    try {
      const service = client.service('search-person');
      service.timeout = 20000;
      dispatch({ type: LOADING_PERSONS, payload: true });
      const persons = await service.find({ query: params });
      dispatch({ type: FIND_PERSONS, payload: { params, persons } });
      dispatch({ type: LOADING_PERSONS, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_PERSONS_ERROR });
      dispatch(
        enqueueSnackbar({
          message: 'No data found.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          }
        })
      );
    }
  };
};

export const sortPersons = orderBy => {
  return async dispatch => {
    const changeDirection = direction => (direction === 'ASC' ? 'DESC' : 'ASC');
    const persons = store.store.getState().personSearch.persons;
    const { filters, pageNo, rowsPerPage } = persons;
    const orderDirection =
      persons.orderBy === orderBy
        ? changeDirection(persons.orderDirection)
        : 'ASC';
    const params = { filters, pageNo, orderBy, rowsPerPage, orderDirection };
    dispatch(findPersons(params));
  };
};

export const findPerson = ptsPersonID => {
  const state = store.store.getState();
  const client = state.websocket.websocket;
  return async dispatch => {
    if (ptsPersonID === null) {
      dispatch({
        type: CLOSE_DETAILS,
        payload: null
      });
    } else {
      dispatch(showSpinner());
      try {
        const service = client.service('search-person');
        service.timeout = 20000;
        const row = await service.get(ptsPersonID);
        dispatch({ type: FIND_PERSON, payload: row });
        dispatch({
          type: OPEN_DETAILS,
          payload: null
        });
      } catch (error) {
        dispatch(
          enqueueSnackbar({
            message: 'No data found.',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            }
          })
        );
      }
      dispatch(hideSpinner());
    }
  };
};

export default function reducer(
  state = {
    // Person
    persons: {
      rows: [],
      count: 5000,
      pageNo: 0,
      search: '',
      rowsPerPage: tableConfig.rowsPerPage,
      orderBy: tableConfig.orderBy,
      orderDirection: tableConfig.orderDirection,
      loading: false,
      loaded: false,
      error: false
    },
    personDetails: {
      data: null,
      ptsPersonID: null,
      isShowing: false
    }
  },
  action
) {
  switch (action.type) {
    // Person
    case LOADING_PERSONS:
      return {
        ...state,
        persons: {
          ...state.persons,
          loading: action.payload,
          loaded: action.payload ? false : state.persons.loaded,
          error: false
        }
      };
    case LOADING_PERSONS_ERROR:
      return {
        ...state,
        persons: {
          ...state.persons,
          loading: false,
          loaded: true,
          error: true
        }
      };
    case FIND_PERSONS:
      return {
        ...state,
        persons: {
          rows: action.payload.persons.data,
          ...action.payload.params,
          count: action.payload.persons.count,
          loaded: true,
          error: false
        }
      };
    case FIND_PERSON:
      return {
        ...state,
        personDetails: { ...state.personDetails, data: action.payload }
      };
    case OPEN_DETAILS:
      return {
        ...state,
        personDetails: { ...state.personDetails, isShowing: true }
      };
    case CLOSE_DETAILS:
      return {
        ...state,
        personDetails: { ...state.personDetails, isShowing: false }
      };
    default:
      break;
  }
  return state;
}
