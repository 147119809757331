import React, { Suspense, Fragment, useState } from 'react';
import store from './config/configureStore';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Spinner from './global-components/Spinner';
import { AnimatePresence, motion } from 'framer-motion';
import { SnackbarProvider } from 'notistack';
import Notifier from 'global-components/Notifier';
import Notifications from 'react-notification-system-redux';

import * as serviceWorker from './serviceWorker';

import AddToHomescreen from 'react-add-to-homescreen';

import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import auth from '@feathersjs/authentication-client';

import { setWebsocketConnection } from './reducers/WebsocketReducer';
import {
  setUserAuthenticated,
  setUserData,
  newUserLocation
} from './reducers/UserReducer';

import { ThemeProvider } from '@material-ui/styles';

import { ClimbingBoxLoader } from 'react-spinners';

import { DayTheme, NightTheme } from './theme';

// Layout Blueprints
import { MinimalLayout, MainLayout, PagesLayout } from './layout-blueprints';

// Pages
import PagesMain from './pages/PagesMain';
import PagesLogin from './pages/PagesLogin';
import PagesSearch from './pages/PagesSearch';

const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/Login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const LoginRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated === true ? (
        <Redirect
          to={{
            pathname: '/Main',
            state: { from: props.location }
          }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const Routes = props => {
  const location = useLocation();
  const {
    isAuthenticated,
    setWebsocketConnection,
    setUserAuthenticated,
    setUserData,
    setLoggedInUserData,
    notifications,
    newUserLocation
  } = props;

  const { showSpinner } = props.ui;

  const [getoWatchState, setState] = useState(false);

  React.useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    const updateApp = registration => {
      window.localStorage.clear();
      const waitingServiceWorker = registration.waiting;

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener('statechange', event => {
          if (event.target.state === 'activated') {
            window.location.reload();
          }
        });
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    };
    const openNotification = registration => {
      const notification = {
        title: `Application Update Available!`,
        message: `New release of CAD MOBILE Application has been released.It is highly recomended to launch the new version!!`,
        position: 'tc',
        level: 'warning',
        autoDismiss: 0,
        action: {
          label: 'LAUNCH!',
          callback: () => updateApp(registration)
        }
      };
      store.store.dispatch(Notifications.success(notification));
    };
    serviceWorker.register({
      onSuccess: () => console.log('service worker registered!'),
      onUpdate: reg => openNotification(reg)
    });
  }, []);

  if (isAuthenticated === true) {
    const hasGeolocationSupport = 'geolocation' in navigator;
    const hasWatchPositionSupport = 'watchPosition' in navigator.geolocation;

    if (hasGeolocationSupport && hasWatchPositionSupport && !getoWatchState) {
      const geoWatch = navigator.geolocation.watchPosition(
        position => {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            altitude: position.coords.altitude,
            accuracy: position.coords.accuracy,
            timestamp: new Date()
          };
          newUserLocation(location);
        },
        err => {
          console.log(err);
        }
      );

      if (geoWatch) {
        setState(true);
      }
    }
  }

  React.useEffect(() => {
    async function establishConnection() {
      const client = feathers();
      client.configure(
        socketio(
          io(process.env.REACT_APP_API_URL, {
            transports: ['websocket', 'polling'],
            perMessageDeflate: false,
            timeout: 20000,
            requestTimeout: 20000
          })
        )
      );
      client.configure(auth({ storageKey: 'auth' }));
      try {
        const user = await client.reAuthenticate();
        setWebsocketConnection(client);
        setUserData(user);
        setUserAuthenticated(true);
      } catch (error) {
        if (error.code === 401) {
          setUserAuthenticated(false);
          setUserData(null);
          setWebsocketConnection(client);
        }
        setWebsocketConnection(client);
      }
    }
    establishConnection();
  }, [
    setWebsocketConnection,
    setUserAuthenticated,
    setLoggedInUserData,
    setUserData
  ]);

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99,
      width: '100%',
      height: '100%'
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#0153a3'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait while we load the view...
          </div>
        </div>
      </Fragment>
    );
  };

  const themeMode = () => {
    let theme = DayTheme;
    if (props.mode === 'night') {
      theme = NightTheme;
    }
    return theme;
  };

  const handleAddToHomescreenClick = () => {
    alert(`
    1. Open Share menu
    2. Tap on "Add to Home Screen" button`);
  };

  return (
    <ThemeProvider theme={themeMode()}>
      {showSpinner && <Spinner />}
      <SnackbarProvider>
        <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} />
        <Notifier />
        <Notifications notifications={notifications} />
        <AnimatePresence>
          <Suspense fallback={<SuspenseLoading />}>
            <Switch>
              <Redirect exact from="/" to="/Login" />

              <Route path={['/Login']}>
                <MinimalLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <LoginRoute
                        path="/Login"
                        component={PagesLogin}
                        isAuthenticated={isAuthenticated}
                      />
                    </motion.div>
                  </Switch>
                </MinimalLayout>
              </Route>

              <Route path={['/Main']}>
                <MainLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ProtectedRoute
                        path="/Main"
                        component={PagesMain}
                        isAuthenticated={isAuthenticated}
                      />
                    </motion.div>
                  </Switch>
                </MainLayout>
              </Route>

              <Route path={['/Search']}>
                <PagesLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ProtectedRoute
                        path="/Search"
                        component={PagesSearch}
                        isAuthenticated={isAuthenticated}
                      />
                    </motion.div>
                  </Switch>
                </PagesLayout>
              </Route>
            </Switch>
          </Suspense>
        </AnimatePresence>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({
  network: state.offline,
  mode: state.theme.mode,
  notifications: state.notifications,
  isAuthenticated: state.user.isAuthenticated,
  ui: state.ui
});

export default connect(mapStateToProps, {
  setWebsocketConnection,
  setUserAuthenticated,
  setUserData,
  newUserLocation
})(Routes);
