import React, { useState } from 'react';
import { connect } from 'react-redux';
import Geocode from 'react-geocode';
import MomentUtils from '@date-io/moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  FormControl,
  TextField
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import {
  toggleDialog,
  unitStatusChange
} from '../../reducers/UnitStatusReducer';
import { getUnitActionCodes } from '../../reducers/DataReducer';
import { getCodeState } from '../../reducers/CodeReducer';

import EventFilter from './EventFilter';
import StatusFilter from './StatusFilter';
import PlateStateFilter from './PlateStateFilter';
import OlnStateFilter from './OlnStateFilter';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh'
  },
  itemWrap: {
    fontWeight: 500
  },
  itemLabel: {
    fontWeight: 200
  },
  formSection: {
    paddingBottom: '10px'
  }
}));

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogUnitStatus(props) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    wsClient,
    isOpen,
    location,
    toggleDialog,
    events,
    myEvents,
    getUnitActionCodes,
    getCodeState,
    unitActionCodes,
    statesCodes,
    unitStatusChange
  } = props;

  const [event, setEvent] = useState('');
  const [status, setStatus] = useState('');
  const [locationString, setLocationString] = useState('');

  const [plate, setPlate] = useState('');
  const [oln, setOln] = useState('');

  const [plateState, setPlateState] = useState('');
  const [olnState, setOlnState] = useState('');

  const [statusNotes, setStatusNotes] = useState('');

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    toggleDialog();
  };

  const submitForm = e => {
    e.preventDefault();
    const _plate = plate !== '' ? plate : null;
    const _plateState = plateState !== '' ? plateState : null;
    const _oln = oln !== '' ? oln : null;
    const _olnState = olnState !== '' ? olnState : null;
    const _notes = statusNotes !== '' ? statusNotes : null;
    unitStatusChange(
      status,
      event,
      locationString,
      _plate,
      _plateState,
      _oln,
      _olnState,
      _notes
    );
  };

  const setSelectedEvent = event => {
    setEvent(event);
  };

  const setSelectedStatus = status => {
    if (location !== null) {
      Geocode.fromLatLng(location.latitude, location.longitude).then(
        response => {
          const address = response.results[0].formatted_address;
          setLocationString(address);
        },
        error => {
          console.error(error);
        }
      );
    }
    setStatus(status);
  };

  const setSelectedPlateState = state => {
    setPlateState(state);
  };

  const setSelectedOlnState = state => {
    setOlnState(state);
  };

  const handleLocationChange = event => {
    setLocationString(event.target.value);
  };

  const handlePlateChange = event => {
    setPlate(event.target.value);
  };

  const handleOlnChange = event => {
    setOln(event.target.value);
  };

  const handleStatusNotesChange = event => {
    setStatusNotes(event.target.value);
  };

  React.useEffect(() => {
    if (wsClient.websocket) {
      getUnitActionCodes();
      getCodeState();
    }
  }, [getUnitActionCodes, getCodeState, wsClient.websocket]);

  return (
    <Dialog
      TransitionComponent={Transition}
      open={isOpen}
      onClose={handleClose}
      scroll="paper"
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth={'sm'}>
      <DialogTitle id="scroll-dialog-title">
        <Typography variant="h4">Unit Status</Typography>
      </DialogTitle>
      <DialogContent
        dividers={true}
        className={classes.body}
        id="unit-status-dialog">
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <form id="status-form" onSubmit={submitForm}>
            <EventFilter
              eventList={events}
              myEvents={myEvents}
              selectedEvent={event}
              setSelectedEvent={setSelectedEvent}
            />
            <StatusFilter
              statusList={unitActionCodes}
              selectedStatus={status}
              setSelectedStatus={setSelectedStatus}
              location={location}
            />
            <FormControl fullWidth className={classes.formSection}>
              <TextField
                variant="outlined"
                label="location"
                className={classes.filter}
                onChange={handleLocationChange}
                value={locationString}
              />
            </FormControl>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  value={plate}
                  onChange={handlePlateChange}
                  fullWidth
                  label="Plate"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <PlateStateFilter
                  states={statesCodes}
                  selectedPlateState={plateState}
                  setSelectedPlateState={setSelectedPlateState}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  value={oln}
                  onChange={handleOlnChange}
                  fullWidth
                  label="OLN"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <OlnStateFilter
                  states={statesCodes}
                  selectedPlateState={olnState}
                  setSelectedOlnState={setSelectedOlnState}
                />
              </Grid>
            </Grid>
            <FormControl fullWidth className={classes.formSection}>
              <TextField
                multiline={true}
                rows="2"
                variant="outlined"
                label="Notes"
                className={classes.filter}
                onChange={handleStatusNotesChange}
                value={statusNotes}
              />
            </FormControl>
          </form>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
          form="status-form">
          Save
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  wsClient: state.websocket,
  isOpen: state.unitStatus.isOpen,
  events: state.events.events,
  statesCodes: state.codes.statesCodes,
  myEvents: state.events.myEvents,
  unitActionCodes: state.data.unitActionCodes,
  location: state.user.location
});

export default connect(mapStateToProps, {
  toggleDialog,
  getUnitActionCodes,
  getCodeState,
  unitStatusChange
})(DialogUnitStatus);
